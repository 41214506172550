import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  fileApiUrl = environment.functionsUrl.concat('/fileApi/file');

  constructor(
    private http: HttpClient
  ) { }

  uploadFileExcelBills(file: File): Observable<any> {
    const url = this.fileApiUrl + '/upload-file-bills';

    return this.http.post(url, file);
  }
}
