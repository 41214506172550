import { analytics } from 'firebase';
import {Claim} from '../models/claim.model';
import {State} from '../models/state.model';

export default class Util {
  static majorDate(dateOne: string, dateTwo: string): string {
    let date;
    let dateExpiration:any;
    const newDateOne = new Date(dateOne);
    const newDateTwo = new Date(dateTwo);
    const timeOne = newDateOne.getTime();
    const timeTwo = newDateTwo.getTime();
    if (timeOne > timeTwo) {
      date = dateOne;
    } else {
      date = dateTwo;
    }

    return date;
  }

  static currentStatus(claim: Claim): string {
    if (!claim) return '';
    let current;
    let major;
    claim.listState.forEach((claimExpirationDate: State) => {
      if (!current) {
        current = claimExpirationDate;
      } else {
        major = Util.majorDate(current.date, claimExpirationDate.date);
        if (claimExpirationDate.date === major) {
          current = claimExpirationDate;
        }
      }
    });
    return current.value;
  }

  static currentReason(claim: Claim): string {
    if (!claim) return '';
    let current;
    let major;
    claim.listState.forEach((claimExpirationDate: State) => {
      if (!current) {
        current = claimExpirationDate;
      } else {
        major = Util.majorDate(current.date, claimExpirationDate.date);
        if (claimExpirationDate.date === major) {
          current = claimExpirationDate;
          
        
          
        }
      }
    });
    return current.reason;
  }

  static currentMajorStatus(claim: Claim, status: string): string {
    if (!claim) return '';
    let current;
    let major;
    claim.listState.forEach((claimExpirationDate: State) => {
      if (!current) {
        current = claimExpirationDate;
      } else if(claimExpirationDate.value === status) {
        major = Util.majorDate(current.date, claimExpirationDate.date);
        if (claimExpirationDate.date === major) {
          current = claimExpirationDate;
        }
      }
    });
    return current.consecutiveNumber;
  }

  static currentDateByStatus(claim: Claim, status: string): string {
    if (!claim) return '';
    let current;
    let major;
    claim.listState.forEach((claimExpirationDate: State) => {
      if (!current) {
        current = claimExpirationDate;
      } else if(claimExpirationDate.value === status) {
        major = Util.majorDate(current.date, claimExpirationDate.date);
        if (claimExpirationDate.date === major) {
          current = claimExpirationDate;
        }
      }
    });
    return current.date;
  }

  static extensionFile(filename: string) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  static memorySizeOf = (o: any) => {
    let bytes = 0;

    const sizeOf = (obj: any) => {
      if (obj !== null && obj !== undefined) {
        switch (typeof obj) {
          case 'number':
            bytes += 8;
            break;
          case 'string':
            bytes += obj.length * 2;
            break;
          case 'boolean':
            bytes += 4;
            break;
          case 'object':
            const objClass = Object.prototype.toString.call(obj).slice(8, -1);
            if (objClass === 'Object' || objClass === 'Array') {
              for (const key in obj) {
                if (!obj.hasOwnProperty(key)) continue;
                sizeOf(obj[key]);
              }
            } else bytes += obj.toString().length * 2;
            break;
        }
      }
      return bytes;
    };

    /*const formatByteSize = (sizeBytes: any) => {
      if(sizeBytes < 1024) return sizeBytes + " bytes";
      else if(sizeBytes < 1048576) return(sizeBytes / 1024).toFixed(3) + " KiB";
      else if(sizeBytes < 1073741824) return(sizeBytes / 1048576).toFixed(3) + " MiB";
      else return(sizeBytes / 1073741824).toFixed(3) + " GiB";
    };*/

    const mbSize = (sizeBytes: any) => {
      return (sizeBytes / 1048576).toFixed(3);
    };
    return mbSize(sizeOf(o));
  }

  static getBase64ImageFromURL(imgId) {
    const imgToExport = document.getElementById(imgId) as CanvasImageSource;
    const canvas = document.getElementById('canvasPhotoFrontDocumentCard') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imgToExport, 0, 0);
    canvas.getContext('2d').drawImage(imgToExport, 0, 0);
    return canvas.toDataURL();
  }

  static toDataURL(url, imageJson: any) {

    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        imageJson.name = reader.result;
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  static currencyformat(value: string) {
    let num: string = value.replace(/\./g, '');
    if (num) {
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
      num = num.split('').reverse().join('').replace(/^[\.]/, '');
    }
    return num;
  }

  static expirationDate(claim: Claim): string {
    if (!claim) return '';
    let current;
    let major;
    claim.listState.forEach((claimExpirationDate: State) => {
      if (!current) {
        current = claimExpirationDate;
      } else {
        major = Util.majorDate(current.date, claimExpirationDate.date);
        if (claimExpirationDate.date === major) {
          current = claimExpirationDate;
        }
      }
    });
    
    const dateClaimRegistrationDate = new Date(current.date.toString());
    dateClaimRegistrationDate.setDate(dateClaimRegistrationDate.getDate() + 30);

    let day: any = dateClaimRegistrationDate.getDate();
    day = (day < 10) ? '0' + day : day;

    let month: any = (dateClaimRegistrationDate.getMonth() + 1);
    month = (month < 10) ? '0' + month : month;

    const showDate = day + '-' + month + '-' + dateClaimRegistrationDate.getFullYear();
    let dateExpiration = (showDate).substring(0,10);
    dateExpiration = dateExpiration.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')
    return dateExpiration;
  }
  static dateAceptClaim(claim: Claim): string {
    if (!claim) return '';
    let current;
    let major;
    claim.listState.forEach((claimAceptDate: State) => {
      if (!current) {
        current = claimAceptDate;
      } else {
        major = Util.majorDate(current.date, claimAceptDate.date);
        if (claimAceptDate.date === major) {
          current = claimAceptDate;
        }
      }
    });
    
    const dateClaimAceptDate = new Date(current.date.toString());
    dateClaimAceptDate.setDate(dateClaimAceptDate.getDate() );

    let day: any = dateClaimAceptDate.getDate();
    day = (day < 10) ? '0' + day : day;

    let month: any = (dateClaimAceptDate.getMonth() + 1);
    month = (month < 10) ? '0' + month : month;

    const showDate = day + '-' + month + '-' + dateClaimAceptDate.getFullYear();
    let dateAcept = (showDate).substring(0,10);
    dateAcept = dateAcept.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')
    return dateAcept;
  }
  static formatregistrationDate(claim: Claim): string {
    let dateRegister = (claim.claimRegistrationDate).substring(0,10);
    dateRegister = dateRegister.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    dateRegister=dateRegister.replace(/[^a-zA-Z0-9]/g, '-');
    return dateRegister;
  }

  static zfill(number, width) {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */ 
    var zero = "0"; /* String de cero */  
    
    if (width <= length) {
        if (number < 0) {
             return ("-" + numberOutput.toString()); 
        } else {
             return numberOutput.toString(); 
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString()); 
        }
    }
  }

  static getDate(): string {
    const date = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Bogota' }));
  
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    let dayString;
  
    if (day < 10) {
      dayString = "0" + day;
    } else {
      dayString = day;
    }
  
    let monthString;
  
    if (month < 10) {
      monthString = "0" + month;
    } else {
      monthString = month;
    }
  
    return `${dayString}-${monthString}-${year}`;
  }
}
