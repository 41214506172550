import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Claim} from '../shared/models/claim.model';

@Injectable({
  providedIn: 'root'
})
export class ClaimService {

  claimApiUrl = environment.functionsUrl.concat('/claimApi/claim');

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient
  ) { }

  findClaimBySticker(sticker: string) {
    return this.firestore
      .collection('claims', ref => ref.where('sticker', '==', sticker))
      .get();
  }

  findClaimByState(state: string) {
    
    /*return this.firestore
      .collection('claims', ref => ref.where('state', '==', state))
      .get();*/

    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    const url = this.claimApiUrl + '/search-claim-for-state';
    const data: any = {
      state: state
    };
    return this.http.post(url, JSON.stringify(data), httpOptions);
  }

  updateStateForClaim(data: any) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    const url = this.claimApiUrl + '/update-state-for-claim';
    return this.http.post(url, JSON.stringify(data), httpOptions);
  }

  create(listData: any) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.claimApiUrl + '/create';
    return this.http.post(url, JSON.stringify(listData), httpOptions);
  }


  uploadOnlyfile(image: any) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.claimApiUrl + '/upload-file-for-claim';
    return this.http.post(url, JSON.stringify(image), httpOptions);
  }

  uploadFilePeaceAndSaveForClaim(claim: Claim, imageObject: any) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.claimApiUrl + '/upload-file-peace-and-save-for-claim';

    const data = {
      claim: claim,
      jsonImage: imageObject
    };
    return this.http.post(url, JSON.stringify(data), httpOptions);
  }

  deleteFilePeaceAndSaveForClaim(claim: Claim) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.claimApiUrl + '/delete-file-peace-and-save-for-claim';

    const data = {
      claim: claim
    };
    return this.http.post(url, JSON.stringify(data), httpOptions);
  }

}
