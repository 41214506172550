import { Component, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

import {
  faSearch,
  faFileUpload,
  faChevronLeft,
  faHandHoldingUsd,
  faUpload
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  /* icons */
  faSearch = faSearch;
  faFileUpload = faFileUpload;
  faChevronLeft = faChevronLeft;
  faHandHoldingUsd = faHandHoldingUsd;
  faUpload = faUpload;


  sidebarClass: string;
  sidebarClassContract: string;
  currentSidebarClass: string;

  menutoggleClass: string;
  menutoggleClassContract: string;
  currentMenutoggleClass: string;

  logoClass: string;
  logoClassContract: string;
  currentLogoClass: string;

  displayNoneClass: string;
  faSizeClass: string;
  navItemClass: string;
  asideClass: string;

  navbarClass: string;

  @Input() appComponent;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.initClass();
  }

  toggleSidebarContract() {
    if (this.currentSidebarClass === this.sidebarClass) {
      this.currentSidebarClass = this.sidebarClassContract;
      this.currentMenutoggleClass = this.menutoggleClassContract;
      this.currentLogoClass = this.logoClassContract;
      this.navbarClass = 'navbar navbar-contract';
      this.displayNoneClass = 'd-none';
      this.faSizeClass = 'fa-2x';
      this.navItemClass = 'nav-item-contract';
      this.asideClass = 'navbar shadow-sm d-flex side-bar-contract';
      this.appComponent.classAppSidebar(true);
    } else {
      this.currentSidebarClass = this.sidebarClass;
      this.currentMenutoggleClass = this.menutoggleClass;
      this.currentLogoClass = this.logoClass;
      this.navbarClass = 'navbar navbar-dark';
      this.displayNoneClass = '';
      this.faSizeClass = 'fa-lg';
      this.navItemClass = 'nav-item';
      this.asideClass = 'navbar shadow-sm d-flex side-bar';
      this.appComponent.classAppSidebar(false);
    }
  }

  initClass() {
    this.sidebarClass = 'left-panel';
    this.sidebarClassContract = this.sidebarClass + ' contract';
    this.currentSidebarClass = this.sidebarClass;

    this.menutoggleClass = 'menutoggle pull-left';
    this.menutoggleClassContract = this.menutoggleClass + ' menutoggle-contract';
    this.currentMenutoggleClass = this.menutoggleClass;

    this.logoClass = 'logo';
    this.logoClassContract = 'logo-contract';
    this.currentLogoClass = this.logoClass;

    this.displayNoneClass = '';
    this.faSizeClass = 'fa-lg';

    this.navbarClass = 'navbar';
    this.navItemClass = 'nav-item';
    this.asideClass = 'navbar shadow-sm d-flex side-bar';
    this.appComponent.classAppSidebar(false);
  }

}

