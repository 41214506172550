import { Component, OnInit } from '@angular/core';
import { DataDbService } from '../../services/data-db.service';
import { BillService } from '../../services/bill.service';
import { ClaimService } from '../../services/claim.service';
import { AngularFireStorage, } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { faCheckCircle, faExclamationCircle, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import  moment from 'moment';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ModalComponent } from '../../shared/modal/modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import Util from '../../shared/utils/util';
import { NgxSpinnerService } from 'ngx-spinner';
import {Claim} from '../../shared/models/claim.model';
import {Bill} from '../../shared/models/bill.model';


@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']



})
export class ClaimComponent implements OnInit {
  /* icons */
  faCheckCircle = faCheckCircle;
  faSearch = faSearch;
  emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  typePattern: any = '(CC|CE|NIT)';
  contactForm: FormGroup;
  rSearchForm: FormGroup;
  validState: string;
  photoDamage: string;
  filePhotoDamage: any;
  photoLicensePlate: string;
  filePhotoLicensePlate: any;
  photoFrontDocumentCard: string;
  filePhotoFrontDocumentCard: any;
  photoUpperDocumentCard: string;
  filePhotoUpperDocumentCard: any;
  photoSticker: string;
  filePhotoSticker: any;
  photoBill: string;
  filePhotoBill: any;
  rNumeroDoc: string;
  rSticker: string;
  validSticker: string;
  rName: string;
  rPlacaVehiculo: any;
  rDateBill: any;
  sendDateBill: any;
  day: string;
  month: string;
  year: string;
  dueDate: any;
  registrationDate = moment().format('YYYY-MM-DD HH:mm:ss');
  search: string;
  value: Date;
  acceptTermsAndConditions: any;
  costCenter: string;
  claim: Claim;
  reasonStatus: string;

  // defaul= "Elija Tipo Documento";
  documentTypes = [
    'Tipo Documento',
    'CC',
    'NIT',
    'CE'
  ];
  public imagePath;
  imgURL1: any;
  imgURL2: any;
  imgURL3: any;
  imgURL4: any;
  imgURL5: any;
  imgURL6: any;
  searchForm: FormGroup;
  modalOptions: NgbModalOptions;
  imageDefault: string;
  listState = [
    {
      value: 'PENDIENTE',
      date: this.registrationDate,
      reason: 'Creacion reclamo',
      user: ''
    },
  ];
  formData: any;
  listData: Array<any>;
  spinnerMessage: string;
  todays: any ;
  today: any ;

  createFormGroup(
    sinisterDate,
    contactName,
    documentNumber,
    phone,
    email,
    photoDamage,
    photoLicensePlate,
    photoFrontDocumentCard,
    photoUpperDocumentCard,
    photoSticker,
    photoBill,
    message,
    documentType,
    acceptTermsAndConditions
  ) {

    this.contactForm = this.formBuilder.group({
      sinisterDate: [sinisterDate, [Validators.required, Validators.minLength(10)]],
      contactName: [contactName, [Validators.required, Validators.minLength(4)]],
      documentNumber: [documentNumber, [Validators.required, Validators.minLength(4)]],
      phone: [phone, [Validators.required, Validators.minLength(7)]],
      email: [email, [Validators.required, Validators.minLength(5), Validators.pattern(this.emailPattern)]],
      photoDamage: [photoDamage],
      photoLicensePlate: [photoLicensePlate],
      photoFrontDocumentCard: [photoFrontDocumentCard],
      photoUpperDocumentCard: [photoUpperDocumentCard],
      photoSticker: [photoSticker],
      photoBill: [photoBill],
      message: [message, [Validators.required, Validators.minLength(30)]],
      documentType: [documentType, [Validators.required, Validators.pattern(this.typePattern)]],
      acceptTermsAndConditions: [acceptTermsAndConditions, [Validators.requiredTrue]]
    });

  }



  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private dbData: DataDbService,
    private formBuilder: FormBuilder,
    private billService: BillService,
    private claimService: ClaimService,
    private spinnerService: NgxSpinnerService,
    private storage: AngularFireStorage) {
    }



  ngOnInit() {
    this.claim = new Claim();
    this.validState = null;
    this.createFormGroup('', '', '', '', '', '', '', '', '', '', '', '', 'Tipo Documento',  false);
    this.rSearchForm = this.formBuilder.group({
      rSearch: ['', []]
    });
    this.modalOptions = {
      backdrop: 'static',
      centered: true,
      backdropClass: 'customBackdrop'
    };
    this.imageDefault = '../../../assets/images/default-image.png';
    this.formData = {};

  }
// Metodo para mostrar el spinner mientras guadamos o ejecutamos alguna peticion que conlleva tiempo de espera
  spinner(): void {
    this.spinnerService.show();
    // setTimeout(() => {
    //   this.spinnerService.hide();
    // },3000);
    }

// metodo que me permite modificar el parametro de la variable terminos y condiciones el cual va a estarr en true o false
  public changed(evt) {
    this.acceptTermsAndConditions = evt.target.checked;
    if (this.acceptTermsAndConditions === false) {
      this.check();
    }
  }


// los metodos onUpload me sirven para la carga de las imagenes en el storage
  onUploadPhotoFrontDocumentCard(e) {
    this.findClaim().subscribe((querySnapshot) => {
      const id = Math.random().toString(36).substring(2);
      const file = e.target.files[0];
      const filepath = `uploads/profile_${id}`;
      const ref = this.storage.ref(filepath);
      const task = this.storage.upload(filepath, file);
      task.snapshotChanges().pipe(finalize(() => {
        ref.getDownloadURL().subscribe((url) => {
          this.photoFrontDocumentCard = url;
        });
      })).subscribe();

    });
  }

  onUploadPhotoUpperDocumentCard(e) {
    this.findClaim().subscribe((querySnapshot) => {
      this.photoUpperDocumentCard = '';
      const id = Math.random().toString(36).substring(2);
      const file = e.target.files[0];
      const filepath = `uploads/profile_${id}`;
      const ref = this.storage.ref(filepath);
      const task = this.storage.upload(filepath, file);
      task.snapshotChanges().pipe(finalize(() => {
        ref.getDownloadURL().subscribe((url) => {
          this.photoUpperDocumentCard = url;
        });
      })).subscribe();

    });
  }

  onUploadPhotoDamage(e) {
    this.findClaim().subscribe((querySnapshot) => {
      const id = Math.random().toString(36).substring(2);
      const file = e.target.files[0];
      const filepath = `uploads/profile_${id}`;
      const ref = this.storage.ref(filepath);
      const task = this.storage.upload(filepath, file);
      task.snapshotChanges().pipe(finalize(() => {
        ref.getDownloadURL().subscribe((url) => {
          this.photoDamage = url;
        });
      })).subscribe();
    });
  }



  onUploadPhotoLicensePlate(e) {
    this.findClaim().subscribe((querySnapshot) => {
      const id = Math.random().toString(36).substring(2);
      const file = e.target.files[0];
      const filepath = `uploads/profile_${id}`;
      const ref = this.storage.ref(filepath);
      const task = this.storage.upload(filepath, file);
      task.snapshotChanges().pipe(finalize(() => {
        ref.getDownloadURL().subscribe((url) => {
          this.photoLicensePlate = url;
        });
      })).subscribe();
    });
  }


  onUploadPhotoSticker(e) {
    this.findClaim().subscribe((querySnapshot) => {
      const id = Math.random().toString(36).substring(2);
      const file = e.target.files[0];
      const filepath = `uploads/profile_${id}`;
      const ref = this.storage.ref(filepath);
      const task = this.storage.upload(filepath, file);
      task.snapshotChanges().pipe(finalize(() => {
        ref.getDownloadURL().subscribe((url) => {
          this.photoSticker = url;
        });
      })).subscribe();
    });
  }

  onUploadPhotoBill(e) {
    this.findClaim().subscribe((querySnapshot) => {
      const id = Math.random().toString(36).substring(2);
      const file = e.target.file[0].name;
      console.log(file);
      const filepath = `uploads/profile_${id}`;
      const ref = this.storage.ref(filepath);
      const task = this.storage.upload(filepath, file);
      task.snapshotChanges().pipe(finalize(() => {
        ref.getDownloadURL().subscribe((url) => {
          this.photoBill = url;
        });
      })).subscribe();
    });
  }


// formateo de formulario, refresh
  onResetForm() {
    this.contactForm.reset();
  }
// busco el stiker en la base de dados claims
  findClaim() {
    const validSticker = this.rSearchForm.get('rSearch').value;
    return this.claimService.findClaimBySticker(validSticker);
  }

// los modal me sirven para mostrar los mensajes en pantalla
  saveModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Guardar Reclamo';
    modalRef.componentInstance.content = '¡ Reclamacion Registrada con Exito!, Revise su correo ' +
      'con el radicado del reclamo, posteriormente ' +
      'se estara dando respuesta a su reclamo en el correo indicado.';
    modalRef.componentInstance.icon = faCheckCircle;

    modalRef.result.then(() => {
      location.href = 'https://www.autoglasscolombia.com/';
    });
  }
  check() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Terminos y Condiciones';
    modalRef.componentInstance.content = '¡ Debe aceptar términos y condiciones de uso !';
    modalRef.componentInstance.icon = faExclamationCircle;
  }
  expirationDate() {
    this.dueDate = moment(this.dueDate).format('DD/MM/YYYY');
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Expiracion vigencia póliza';
    modalRef.componentInstance.content = '¡Su póliza de vidrio está por fuera de la vigencia de 180 días, ' +
      'vencio el dia ' + this.dueDate + '!';
    modalRef.componentInstance.icon = faExclamationCircle;
    modalRef.result.then(() => {
      location.href = 'https://www.autoglasscolombia.com/';
    });
  }

  noSaveModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Reclamo No Guardado';
    modalRef.componentInstance.content = 'No se pudo registrar el reclamo, por favor intentar mas tarde.';
    modalRef.componentInstance.icon = faCheckCircle;
  }
  claimNotFoundModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Consultar reclamo';
    modalRef.componentInstance.content = '¡El número de sticker no existe!';
    modalRef.componentInstance.icon = faExclamationCircle;
  }
  noOnline() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Acceso a Internet';
    modalRef.componentInstance.content = '¡No tienes acceso a internet!';
    modalRef.componentInstance.icon = faExclamationCircle;
  }
  claimErrorModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Consultar reclamo';
    modalRef.componentInstance.content = 'Se produjo un error al realizar la consulta. Vuelva a intentarlo mas tarde.';
    modalRef.componentInstance.icon = faTimesCircle;
    modalRef.result.then(() => {
      location.href = 'https://www.autoglasscolombia.com/';
    });

  }
  validclaimModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Validación Reclamo';
    modalRef.componentInstance.content = 'Su reclamo ya fue ingresado y se encuentra en estado ' + this.validState +
      ' , no es necesario registrar un nuevo reclamo bajo el stiker # ' + this.rSticker +
      ' si después de 48 horas no se ha emitido alguna respuesta.';
    modalRef.componentInstance.icon = faExclamationCircle;
    modalRef.componentInstance.hyperlink = 'https://www.autoglasscolombia.com/contactenos/';
    modalRef.result.then(() => {
      location.href = 'https://www.autoglasscolombia.com/';
    });

  }

  // me sirven para la previsualizacion de las imagenes enviadas desde el formulario
  viewImage(title: string, image: string) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      centered: true,
      size: 'lg'
    };

    const modalRef = this.modalService.open(ModalComponent, modalOptions);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.image = image;
  }

// aca recibo la imagen o pdf y una cadena con la descripcion
  preViewImage(files, imagen: string) {
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null && mimeType !== 'application/pdf') {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;

    reader.readAsDataURL(files[0]);
    const extension = Util.extensionFile(files[0].name);

    reader.onload = () => {
      if (imagen === 'Documento Frontal') {
        this.imgURL1 = reader.result;
        this.filePhotoFrontDocumentCard = {
          image: reader.result,
          contentType: mimeType,
          type: extension,
          typeData: 'image',
          attributeName: 'photoFrontDocumentCard'
        };

      }
      if (imagen === 'Documento Posterior') {
        this.imgURL2 = reader.result;
        this.filePhotoUpperDocumentCard = {
          image: reader.result,
          contentType: mimeType,
          type: extension,
          typeData: 'image',
          attributeName: 'photoUpperDocumentCard'
        };
      }
      if (imagen === 'Daño') {
        this.imgURL3 = reader.result;
        this.filePhotoDamage = {
          image: reader.result,
          contentType: mimeType,
          type: extension,
          typeData: 'image',
          attributeName: 'photoDamage'
        };
      }
      if (imagen === 'Placa') {
        this.imgURL4 = reader.result;
        this.filePhotoLicensePlate = {
          image: reader.result,
          contentType: mimeType,
          type: extension,
          typeData: 'image',
          attributeName: 'photoLicensePlate'
        };
      }
      if (imagen === 'Stickers') {
        this.imgURL5 = reader.result;
        this.filePhotoSticker = {
          image: reader.result,
          contentType: mimeType,
          type: extension,
          typeData: 'image',
          attributeName: 'photoSticker'
        };
      }
      if (imagen === 'Factura') {
        this.imgURL6 = reader.result;
        this.filePhotoBill = {
          image: reader.result,
          contentType: mimeType,
          type: extension,
          typeData: 'image',
          attributeName: 'photoBill'
        };
      }

    };
  }

// formateo los parametros requerid
  resetForm() {
    this.rSearchForm = this.formBuilder.group({
      search: ['', Validators.required]
    });
  }

  form() {
    if (!this.contactForm.valid) {
      this.claimErrorModal();
    }

  }

  // este metodo me sirve para recuperar datos ya guardados en la BD tanto en las tablas de bill y claim
  rFindClaim() {
    this.rNumeroDoc = '';
    this.rName = '';
    this.rPlacaVehiculo = '';
    this.validState = null;
    this.createFormGroup('', '', '', '', '', '', '', '', '', '', '', '', 'Tipo Documento',  false);
    const rSticker = this.rSearchForm.get('rSearch').value;
    this.billService.findByStickerBills(rSticker).subscribe(async (querySnapshot) => {
      if (querySnapshot.size > 0) {
        this.spinnerMessage = 'Consultando poliza...';
        this.spinner();
        await querySnapshot.forEach((doc) => {
          const bill = doc.data() as Bill;
          this.costCenter = bill.costCenter;
          this.dueDate = bill.dueDate.toString();
          this.rSticker = bill.sticker;

         // esta validacionde fecha es para identificar si aplica hacer la reclamacion el campo dueDatede la BD bill confiene la
         // fecha de vencimiento
          this.year = this.dueDate.substring(0, 4);
          this.month = this.dueDate.substring(4, 6);
          this.day = this.dueDate.substring(6, 8);
          this.dueDate = this.year.concat('/', this.month, '/', this.day, ' ', '23:59:59');
          this.dueDate = new Date(this.dueDate);
          this.today = new Date();
          if (this.today <= this.dueDate) {
            this.rNumeroDoc = bill.clientNit;
            this.rName = bill.clientName;
            this.rPlacaVehiculo = bill.licensePlate;


            // aca estoy consultando la fecha en que se hizo la venta campo date de la BD bill con el fin de solitar
            // foto de la factura
            this.rDateBill = bill.date.toString();
            this.year = this.rDateBill.substring(0, 4);
            this.month = this.rDateBill.substring(4, 6);
            this.day = this.rDateBill.substring(6, 8);
            this.rDateBill = this.year.concat('/', this.month, '/', this.day, ' ', '23:59:59');
            this.rDateBill = new Date(this.rDateBill);
            this.todays = new Date('2020-01-01');

            if (this.rDateBill < this.todays) {
              this.sendDateBill = this.rDateBill;
            } else {
              this.sendDateBill = false;
            }

          } else {
            this.spinnerService.hide();
            this.expirationDate();
            this.rNumeroDoc = '';
            this.rName = '';
            this.rPlacaVehiculo = '';
        }

        });

        if (navigator.onLine) {
          this.claimService.findClaimBySticker(rSticker).subscribe((querySnapshotResultClaim) => {
            this.spinnerService.hide();
            if ( querySnapshotResultClaim.size > 0) {
              let currentClaim: Claim = null;
              querySnapshotResultClaim.forEach((docClaim) => {
                currentClaim = docClaim.data() as Claim;
                currentClaim.id = docClaim.id;
                this.claim = currentClaim;
                this.validState = Util.currentStatus(currentClaim);
                this.reasonStatus = Util.currentReason(currentClaim);
              });
              if (this.validState !== 'OBJETADO') {
                this.validclaimModal();
              } else {
                this.imgURL1 = currentClaim.photoFrontDocumentCard;
                this.imgURL2 = currentClaim.photoUpperDocumentCard;
                this.imgURL3 = currentClaim.photoDamage;
                this.imgURL4 = currentClaim.photoLicensePlate;
                this.imgURL5 = currentClaim.photoSticker;
                this.imgURL6 = currentClaim.photoBill;
                this.acceptTermsAndConditions = currentClaim.acceptTermsAndConditions;

                this.createFormGroup(
                  currentClaim.sinisterDate,
                  currentClaim.contactName,
                  currentClaim.documentNumber,
                  currentClaim.phone,
                  currentClaim.email,
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  currentClaim.message,
                  currentClaim.documentType,
                  currentClaim.acceptTermsAndConditions
                );
              }
            }
          }, (error) => {
            // console.log(error);
            this.spinnerService.hide();
            this.claimErrorModal();
          });
        } else {
          this.spinnerService.hide();
        }
      } else {
        this.claimNotFoundModal();
      }
    }, (error) => {
      console.log(error);
      this.claimErrorModal();
    });
  }
// metodo que me guarda en la BD
  public onSaveForm() {
    this.spinnerMessage = 'Enviando datos del Reclamo...';
    if ((this.contactForm.valid) && (this.rNumeroDoc)) {
       // this.validclaimModal();

      this.claim.sinisterDate = this.contactForm.get('sinisterDate').value.replace(/ /g, '');
      this.claim.contactName = this.contactForm.get('contactName').value;
      this.claim.documentType = this.contactForm.get('documentType').value;
      this.claim.documentNumber = this.contactForm.get('documentNumber').value;
      this.claim.phone = this.contactForm.get('phone').value;
      this.claim.email = this.contactForm.get('email').value.toLowerCase();
      this.claim.message = this.contactForm.get('message').value;
      this.claim.sticker = this.rSticker;
      this.claim.claimRegistrationDate = this.registrationDate;

      if (this.claim.listState) {
        if (this.claim.listState.length > 0) {
          this.claim.listState.push(this.listState[0]);
        } else {
          this.claim.listState = this.listState;
        }
      } else {
        this.claim.listState = this.listState;
      }
      this.claim.acceptTermsAndConditions = this.contactForm.get('acceptTermsAndConditions').value;
      this.claim.pointSale = this.costCenter;

      if ((navigator.onLine) && ((this.validState === 'OBJETADO') || (!this.validState))) {
        this.spinner();
        // activa loading
        this.listData = [];
        if (this.filePhotoFrontDocumentCard) this.listData.push(this.filePhotoFrontDocumentCard);
        if (this.filePhotoBill) this.listData.push(this.filePhotoBill);
        if (this.filePhotoSticker)this.listData.push(this.filePhotoSticker);
        if (this.filePhotoLicensePlate)this.listData.push(this.filePhotoLicensePlate);
        if (this.filePhotoDamage)this.listData.push(this.filePhotoDamage);
        if (this.filePhotoUpperDocumentCard)this.listData.push(this.filePhotoUpperDocumentCard);
        this.listData.push({
          typeData: 'data',
          data: this.claim
        });

        if (+Util.memorySizeOf(this.listData) > 9.999) {
          this.uploadOnlyFile(this.listData);
        } else {
          this.claimService.create(this.listData).subscribe((querySnapshotCreate: any) => {
            this.spinnerService.hide();
            if (querySnapshotCreate.claim) {

              this.saveModal();
            } else if (querySnapshotCreate.error) {
            }
          }, (error) => {
            this.spinnerService.hide();
            console.error('Error adding document: ', error);
          });
        }
      } else {
        if (!navigator.onLine) {
          this.noOnline();
        } else {
          this.validState = null;
        }
      }
    } else {
     // this.expirationDate();
    }
  }
// metodo que me permite validar el tamaño de las imagenes para guardarlas controladamente
  uploadOnlyFile = (listData: Array<any>) => {
    const object = listData[0];
    const data = listData.filter((obj: any) => obj.typeData === 'data')[0];
    this.claimService.uploadOnlyfile(object).subscribe((querySnapshot: any) => {
      if (querySnapshot.url) {
        data.data[object.attributeName] = querySnapshot.url;
        data.data[object.attributeName + 'Path'] = querySnapshot.path;
        listData.shift();
        if (+Util.memorySizeOf(listData) > 9.999) {
          this.uploadOnlyFile(listData);
        } else {
          this.claimService.create(listData).subscribe((querySnapshotCreate: any) => {
            this.spinnerService.hide();
            if (querySnapshotCreate.claim) {

              this.validclaimModal();
            } else if (querySnapshotCreate.error) {
            }
          }, (error) => {
            this.spinnerService.hide();
            console.error('Error adding document: ', error);
          });
        }
      } else if (querySnapshot.error) {
      }
    }, (error) => {
      this.spinnerService.hide();
      console.error('Error adding document: ', error);
    });
  }

  get sinisterDate() { return this.contactForm.get('sinisterDate'); }
  get contactName() { return this.contactForm.get('contactName'); }
  get documentType() { return this.contactForm.get('documentType'); }
  get documentNumber() { return this.contactForm.get('documentNumber'); }
  get phone() { return this.contactForm.get('phone'); }
  get email() { return this.contactForm.get('email'); }
  get message() { return this.contactForm.get('message'); }
  get sticker() { return this.sticker; }
  get claimRegistrationDate() { return this.claimRegistrationDate; }
  get Checked() { return this.acceptTermsAndConditions; }
}
