import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BillService {

  billApiUrl = environment.functionsUrl.concat('/billApi/bill');

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient
  ) { }

  findByStickerBills(rSticker: string) {
    return this.firestore
      .collection('bills', ref => ref.where('sticker', '==', rSticker))
      .get();
  }

  // .orderBy('claimRegistrationDate', 'asc')
   findByStateClaim(rSticker: string)  {

    return   this.firestore
      .collection('claims', ref => ref.where('sticker', '==', rSticker))
      .get()

  }

  findBillByStickerForPdf(sticker: string) {
    /*return this.firestore
      .collection('claims', ref => ref.where('state', '==', state))
      .get();*/

    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    const url = this.billApiUrl + '/search-bill-claim-pdf';
    const data: any = {
      sticker: sticker
    };
    return this.http.post(url, JSON.stringify(data), httpOptions);
  }

}
