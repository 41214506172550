import {State} from './state.model';

export interface IClaim {
  id?: string;
  claimRegistrationDate?: string;
  contactName?: string;
  description?: string;
  documentType?: string;
  documentNumber?: string;
  email?: string;
  phone?: string;
  photoDamage?: string;
  photoFrontDocumentCard?: string;
  photoLicensePlate?: string;
  photoSticker?: string;
  photoUpperDocumentCard?: string;
  pointSale?: string;
  sinisterDate?: string;
  state?: string;
  sticker?: string;
  message?: string;
  photoBill?: string;
  photoBillPath?: string;
  acceptTermsAndConditions?: boolean;
  photoPeaceAndSaveDocument?: string;
  photoPeaceAndSaveDocumentPath?: string;
  peaceAndSaveDocument?: string;
}

export class Claim implements IClaim {
  constructor(
    public id?: string,
    public claimRegistrationDate?: string,
    public contactName?: string,
    public documentType?: string,
    public documentNumber?: string,
    public email?: string,
    public phone?: string,
    public photoDamage?: string,
    public photoFrontDocumentCard?: string,
    public photoLicensePlate?: string,
    public photoSticker?: string,
    public photoUpperDocumentCard?: string,
    public pointSale?: string,
    public sinisterDate?: string,
    public listState?: Array<State>,
    public sticker?: string,
    public message?: string,
    public photoBill?: string,
    public photoBillPath?: string,
    public acceptTermsAndConditions?: boolean,
    public photoPeaceAndSaveDocument?: string,
    public peaceAndSaveDocument?: string,
    public photoPeaceAndSaveDocumentPath?: string
  ) {}
}
