import { Component, OnInit } from '@angular/core';
import { ListPriceService } from 'src/app/services/list-price.service';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { faExclamationCircle, faCartPlus, faBinoculars, faCar, faGlasses, faUpload, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalComponent } from '../../shared/modal/modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireStorage, } from '@angular/fire/storage';

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit {
  queryForm: FormGroup;
  spinnerMessage: string;
  faCartPlus = faCartPlus;
  faCar = faCar;
  faGlasses = faGlasses;
  faUpload = faUpload;
  faBinoculars = faBinoculars;
  gender: any;
  resultURLCar: any;
  resultURLReference: any;
  parametersStoreProcedure: any;
  parameters: any;
  modalOptions: NgbModalOptions;
  modalUrl = '#imageCarReferenceModal';
  reference: string;

  // modal para guardar
  imgURLReference: any;
  filePathReference: string;
  imgURLCar: any;
  filePathCar: string;
  referenciaGlass: string;
  referenciaCar: string;
  fileReference: File;
  fileCar: File;
  urlReference = '';
  urlCar = '';
  imageUrl: any;
  resultUrl: string;
  myForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    public listPriceService: ListPriceService,
    public formBuilder: FormBuilder,
    private afStorage: AngularFireStorage,
    private modalService: NgbModal,

  ) {
    this.myForm = this.fb.group({
      img: [null],
      filename: ['']
    })
  }
  ngOnInit() {
    this.queryForm = this.formBuilder.group({
      ano: ['', ''],
      brand: ['', ''],
      styles: ['', ''],
      descripcion: ['', ''],
      referencia: ['', ''],
    });

  }
  noChooseListPrice() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Lista Precios';
    modalRef.componentInstance.content = 'No se encontro ningun registro con los datos filtrados.';
    modalRef.componentInstance.icon = faExclamationCircle;
  }

  isEmptyListPrice() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Lista Precios';
    modalRef.componentInstance.content = 'Debes de ingresar algún dato en el formulario.';
    modalRef.componentInstance.icon = faExclamationCircle;
  }

  spinner(): void {
    this.spinnerService.show();
  }
  spinnerStop(): void {
    this.spinnerService.hide();
  }

  resultQuery: any;
  list_new: any;
  public onConsultFormList() {
    this.spinnerMessage = 'Procesando...';
    this.spinner();
    this.parameters = {
      ano: this.queryForm.get('ano').value,
      marca: this.queryForm.get('brand').value,
      estilo: this.queryForm.get('styles').value,
      descripcion: this.queryForm.get('descripcion').value,
      referencia: this.queryForm.get('referencia').value
    }
    // valido que la variable this.parameters si tenga datos en por lo menos una de sus keys
    if(!Object.keys(this.parameters).every((k) => this.parameters[k] == false)){
      console.log('parametros enviados ' +this.parameters.ano);
      this.listPriceService.getList(this.parameters).subscribe((result: any) => {
        this.resultQuery = result.lista;
        if (result.message) {
          this.spinnerStop();
          this.noChooseListPrice();
        }
        else {
          this.spinnerStop();
          this.filterList(this.resultQuery, this.parameters);
          this.resultQuery = {};
          this.parameters = {};
        }
      }, (error) => {
        console.log(error);
      });
    }
    else {
      this.spinnerStop();
      this.isEmptyListPrice();
    }

  }

  public clearForm() {
    this.ngOnInit();
  }
  // valido si el objeto esta vacio antes de llamara la function
  public isEmptyObject(obj) {
    for (var property in obj) {
      if (obj.hasOwnProperty(property)) {
          return false;
      }
  }

  return true;
  }

  public filterList(array: any, parametros: any) {
    let filter_list = [];
    array.forEach(function (array, index) {
      filter_list.push({
        "bodega": array.bodega,
        "referencia": array.referencia,
        "descripcion": array.descripcion,
        "brandDescripModel": array.brandDescripModel,
        "unidades": array.unidades,
        "valorClient": array.valorClient,
        "valor2": array.valor2,
        "valor3": array.valor3,
        "urlcar": array.urlcar,
        "urlreference": array.urlreference
      }
      )
    });
    this.list_new = filter_list;
    if (this.list_new == "") {
      this.noChooseListPrice();
    }

  }
  viewReference($event) {
    this.spinner();
    this.reference = $event;
    this.listPriceService.getSearchReference(this.reference).subscribe((result: any) => {
      this.resultURLCar = result.listPrice['urlcar'];
      this.resultURLReference = result.listPrice['urlreference'];
      this.modalUrl;
      this.spinnerStop();
    }, (error) => {
      console.log(error);
    });
  }

  // modal para guardar
  noSaveModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Imagenes no guardadas';
    modalRef.componentInstance.content = 'No se pudo guardar las imagenes, intenta mas tarde.';
    modalRef.componentInstance.icon = faCheckCircle;
  }
  saveModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Guardar Imagenes';
    modalRef.componentInstance.content = '¡ Se guardaron las imagenes con éxito '
    modalRef.componentInstance.icon = faCheckCircle;
  }

  imagePreviewReference(e: any, action: any) {
    this.imgURLReference = e;
    const fileReference = (e.target as HTMLInputElement).files[0];
    this.myForm.patchValue({
      img: fileReference
    });

    this.myForm.get('img').updateValueAndValidity()
    const reader = new FileReader();
    reader.onload = () => {
      this.filePathReference = reader.result as string;
    }
    reader.readAsDataURL(fileReference)
    if (action === 'save') {
      this.uploadFile(fileReference, 'Reference')
    }

  }
  imagePreviewCar(e: any, action: any) {
    this.imgURLCar = e;
    const fileCar = (e.target as HTMLInputElement).files[0];
    this.myForm.patchValue({
      img: fileCar
    });

    this.myForm.get('img').updateValueAndValidity()
    const reader = new FileReader();
    reader.onload = () => {
      this.filePathCar = reader.result as string;
    }
    reader.readAsDataURL(fileCar)
    if (action === 'save') {
      this.uploadFile(fileCar, 'Car')
    }

  }
  submit() {
    this.spinner();
    this.imagePreviewReference(this.imgURLReference, 'save')
    this.imagePreviewCar(this.imgURLCar, 'save')

  }
  //method to upload file at firebase storage
  async uploadFile(file, types) {
    let folder = '/imageGlass';
    if (types === 'Reference') {
      this.referenciaGlass = 'Glass' + this.reference;
      this.fileReference = file;
      if (this.fileReference) {
        const filePathReference = `${folder}/${this.referenciaGlass}`;    //path at which image will be stored in the firebase storage
        const snap = await this.afStorage.upload(filePathReference, this.fileReference);    //upload task
        this.getUrl(snap, types);
      } else { alert('Por favor Seleccionar una imagen para ' + types); }
    } else if (types === 'Car') {
      this.referenciaCar = 'Car' + this.reference;
      this.fileCar = file;
      if (this.fileCar) {
        const filePathCar = `${folder}/${this.referenciaCar}`;    //path at which image will be stored in the firebase storage
        const snap = await this.afStorage.upload(filePathCar, this.fileCar);    //upload task
        this.getUrl(snap, types);
      } else { alert('Por favor Seleccionar una imagen para ' + types); }
    }
  }
  //method to retrieve download url
  private async getUrl(snap: firebase.storage.UploadTaskSnapshot, actionType: any) {
    const url = await snap.ref.getDownloadURL();
    if (actionType === 'Reference') {
      this.urlReference = url;  //store the URL
    } else if (actionType === 'Car') {
      this.urlCar = url;  //store the URL
    }
    this.imageUrl = {
      referencia: this.reference,
      urlcar: this.urlCar,
      urlreference: this.urlReference,
    }

    if (this.imageUrl.urlcar != '' && this.imageUrl.urlreference) {
      this.listPriceService.ImageList(this.imageUrl).subscribe((resultUrl: any) => {
        this.resultUrl = resultUrl;
        this.spinnerStop();
        this.saveModal();
        this.clearForm();
      }, (error) => {
        console.log(error);
      });
    }
  }
}
