export const environment = {
  production: true,
  environmentName: 'PDN',
  firebaseConfig : {
    apiKey: 'AIzaSyCWwPi-XF-HFe7jJdxlrmjEaHq3piy93_Q',
    authDomain: 'vidriera-f3d02.firebaseapp.com',
    databaseURL: 'https://vidriera-f3d02.firebaseio.com',
    projectId: 'vidriera-f3d02',
    storageBucket: 'vidriera-f3d02.appspot.com',
    messagingSenderId: '502011654082',
    appId: '1:502011654082:web:bf2940a2ff0f1f4edf305b'
  },
  functionsUrl: 'https://us-central1-vidriera-f3d02.cloudfunctions.net',
  functionsUrlFavicon: 'https://firebasestorage.googleapis.com/v0/b/vidriera-f3d02.appspot.com/o/resources%2Ffavicon.png?alt=media&token=9ca617e5-4f30-4172-9bde-491232acb147',
  functionsUrlLogoAut: 'https://firebasestorage.googleapis.com/v0/b/vidriera-f3d02.appspot.com/o/resources%2FLogo-Autoglass.png?alt=media&amp;token=1b6c65b1-413e-437d-ac85-68c3f87d2867',
  functionsUrlLogoCole: 'https://firebasestorage.googleapis.com/v0/b/vidriera-f3d02.appspot.com/o/resources%2Flogo_cole.jpeg?alt=media&token=ac3e4802-1223-4b8d-b18b-61ae2e8a1ce6',
  functionsUrlFactura: 'https://firebasestorage.googleapis.com/v0/b/vidriera-f3d02.appspot.com/o/resources%2FFactura_PDF.png?alt=media&token=6eded081-d43b-4996-867c-795f3df05bbb',
  functionsUrlSeguro: 'https://firebasestorage.googleapis.com/v0/b/vidriera-f3d02.appspot.com/o/resources%2FlogoSeguro.png?alt=media&token=8dc0e5e4-b1cb-4677-9ca5-f1e424316cb0'
};
