import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../services/auth.service';
import {ClaimService} from '../../../../services/claim.service';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {faAngleDoubleRight, faDownload, faExclamationCircle, faFileExcel, faSearch} from '@fortawesome/free-solid-svg-icons';
import {Claim} from '../../../../shared/models/claim.model';
import {ModalComponent} from '../../../../shared/modal/modal.component';
import Util from '../../../../shared/utils/util';
import {NgxSpinnerService} from 'ngx-spinner';
import { DownloadService } from 'src/app/services/download.service';

@Component({
  selector: 'app-search-claim-state',
  templateUrl: './search-claim-state.component.html',
  styleUrls: ['./search-claim-state.component.scss']
})
export class SearchClaimStateComponent implements OnInit {

  /* icons */
  faSearch = faSearch;
  faAngleDoubleRight = faAngleDoubleRight;
  faFileExcel = faFileExcel;

  searchForm: FormGroup;
  existResult: boolean;
  claims;
  modalOptions: NgbModalOptions;
  spinnerMessage: string;
  state: string;
  util: any;

  constructor(

    private authService: AuthService,
    private claimService: ClaimService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService,
    private downloadService: DownloadService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      centered: true,
      backdropClass: 'customBackdrop',
      size: 'lg'
    };
  }

  ngOnInit() {
    this.resetForm();
    this.util = Util;
  }

  resetForm() {
    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required]
    });
  }

  findClaim() {
    this.existResult = false;
    this.claims = null;
    this.state = this.searchForm.controls['search'].value;
    this.spinnerMessage = 'Consultando reclamos en estado ' + this.state + '...';
    this.spinnerService.show();
    this.claimService.findClaimByState(this.state).subscribe((querySnapshot: any) => {
      this.spinnerService.hide();
      this.claims = [];
      if (querySnapshot.claims.length > 0) {
        this.existResult = true;
        querySnapshot.claims.forEach((doc) => {
          this.claims.push(doc);
        });
      } else {
        this.claimNotFoundModal();
      }
    }, (error) => {
      console.log(error);
      this.spinnerService.hide();
      this.claimNotFoundModal();
      // this.claimErrorModal();
    });
  }

  claimDetail(claim: Claim) {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Póliza';
    modalRef.componentInstance.claim = claim;
    modalRef.result.then((response) => {
      if (response && response.sticker) {
        'inn'
        claim.listState = response.listState;
      }
    });
  }

  claimNotFoundModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Consultar reclamo';
    modalRef.componentInstance.content = 'No existen reclamos con estado: ' + this.searchForm.controls['search'].value;
    modalRef.componentInstance.icon = faExclamationCircle;
  }

  currentStatus(claim: Claim) {
    return Util.currentStatus(claim);
  }

  currentReason(claim: Claim) {
    return Util.currentReason(claim);
  }

  expirationDate(claim: Claim) {
    return Util.expirationDate(claim);
    
  }
  registrationDate(claim: Claim) {
    return Util.formatregistrationDate(claim);
    
  }
  dateAceptClaim(claim: Claim) {
    return Util.dateAceptClaim(claim);
    
  }

  downloadCSV() {
    const fields = [
      {
        label: 'Sticker',
        value: 'sticker'
      },
      {
        label: 'Punto de venta',
        value: 'pointSale'
      },
      {
        label: 'Fecha de creación',
        value: 'claimRegistrationDate'
      },
      {
        label: 'Fecha del siniestro',
        value: 'sinisterDate'
      },
      {
        label: 'Descripción del siniestro',
        value: 'message'
      },
      {
        label: 'Nombre de contacto',
        value: 'contactName'
      },
      {
        label: 'Tipo de documento',
        value: 'documentType'
      },
      {
        label: 'Número de documento',
        value: 'documentNumber'
      },
      {
        label: 'Correo electrónico',
        value: 'email'
      },
      {
        label: 'Número de contacto',
        value: 'phone'
      },
      {
        label: 'Estado',
        value: 'state'
      },
      {
        label: 'Fecha de cambio de estado',
        value: 'dateState'
      }
    ];

    let rows = [];
    let row: any;

    const state = this.searchForm.controls['search'].value;
    this.claims.forEach((claim: any) => {
      row = {
        sticker: claim.sticker,
        pointSale: claim.pointSale,
        claimRegistrationDate: claim.claimRegistrationDate,
        sinisterDate: claim.sinisterDate.replaceAll('/', '-'),
        message: claim.message,
        contactName: claim.contactName,
        documentType: claim.documentType,
        documentNumber: claim.documentNumber,
        email: claim.email,
        phone: claim.phone,
        state: state,
        dateState: Util.currentDateByStatus(claim, state)
      };

      rows.push(row);
    });
    this.downloadService.downloadFile(rows, fields, 'reclamos_' + state + '_' + Util.getDate());
  }
}
