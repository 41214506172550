import {Component, OnInit} from '@angular/core';
import {AuthService} from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],


})
export class AppComponent implements OnInit {
  title = 'vidriera-Form';

  public sidebarClass: string;

  appComponent: any;

  constructor(private authService: AuthService) {

  }

  ngOnInit() {
    this.appComponent = this;
  }

  classAppSidebar(isContract: boolean) {
    if (isContract) {
      this.sidebarClass = 'shadow-sm app-sidebar-contract';
    } else {
      this.sidebarClass = 'app-sidebar';
    }
  }
}
