import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {Claim} from '../shared/models/claim.model';
import { Bill } from '../shared/models/bill.model';
import Util from '../shared/utils/util';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfMakeService {

  constructor() { }

  generatePdf(
    claim: Claim,
    photoFrontDocumentCard: string,
    photoUpperDocumentCard: string,
    photoSticker: string,
    photoLicensePlate: string,
    photoDamage: string) {
    pdfMake.createPdf(this.getDocumentxDefinition(
      claim,
      photoFrontDocumentCard,
      photoUpperDocumentCard,
      photoSticker,
      photoLicensePlate,
      photoDamage)).download('Poliza-' + claim.sticker + '.pdf');
  }

  getDocumentxDefinition(
    claim: Claim,
    photoFrontDocumentCard: string,
    photoUpperDocumentCard: string,
    photoSticker: string,
    photoLicensePlate: string,
    photoDamage: string) {
    return {
      content: [
        {
          text: 'Poliza Sticker - ' + claim.sticker,
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          text: 'Poliza Sticker - ' + claim.sticker,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Fecha de la reclamación: ' + claim.claimRegistrationDate,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Fecha del evento: ' + claim.sinisterDate,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Tipo de documento: ' + claim.documentType,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Número de documento: ' + claim.documentNumber,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Nombre de reclamante: ' + claim.contactName,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Número de contacto: ' + claim.phone,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Email: ' + claim.email,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Punto de venta: ' + claim.pointSale,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Descripción: ',
          margin: [0, 0, 0, 10]
        },
        {
          text: claim.message,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Documento cara frontal: ',
          margin: [0, 0, 0, 10]
        },
        {
          image: photoFrontDocumentCard,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Documento cara posterior: ',
          margin: [0, 0, 0, 10]
        },
        {
          image: photoUpperDocumentCard,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Sticker: ',
          margin: [0, 0, 0, 10]
        },
        {
          image: photoSticker,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Número de placa: ',
          margin: [0, 0, 0, 10]
        },
        {
          image: photoLicensePlate,
          margin: [0, 0, 0, 10]
        },
        {
          text: 'Foto del daño: ',
          margin: [0, 0, 0, 10]
        },
        {
          image: photoDamage,
          margin: [0, 0, 0, 10]
        }
      ],
      styles: {
        name: {
          fontSize: 16,
          bold: true
        }
      }
    };
  }

  generatePdfDocumentIdentification(claim: Claim, photoFrontDocumentCard: string, photoUpperDocumentCard: string) {
    return pdfMake.createPdf(this.getDocumentIdentificationDefinition(
      claim,
      photoFrontDocumentCard,
      photoUpperDocumentCard)
    ).download('Poliza-' + claim.sticker + '-documento_identificacion.pdf');
  }

  generatePdfDocumentIdentificationCreate(claim: Claim, photoFrontDocumentCard: string, photoUpperDocumentCard: string) {
    return pdfMake.createPdf(this.getDocumentIdentificationDefinition(
      claim,
      photoFrontDocumentCard,
      photoUpperDocumentCard)
    );
  }

  getDocumentIdentificationDefinition(claim: Claim, photoFrontDocumentCard: string, photoUpperDocumentCard: string) {
    return {
      content: [
        {
          text: 'Poliza - ' + claim.sticker,
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          text: 'Documento de identificación - ' + claim.documentType + ' ' + claim.documentNumber,
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20],
        },
        {
          image: photoFrontDocumentCard,
          margin: [0, 0, 0, 10],
          maxWidth: 500,
          alignment: 'center'
        },
        {
          image: photoUpperDocumentCard,
          margin: [0, 0, 0, 10],
          maxWidth: 500,
          alignment: 'center'
        }
      ],
      styles: {
        name: {
          fontSize: 16,
          bold: true
        }
      }
    };
  }

  generatePdfVehicle(claim: Claim, photoSticker: string, photoLicensePlate: string, photoDamage: string) {
    pdfMake.createPdf(this.getVehicleDefinition(
      claim,
      photoSticker,
      photoLicensePlate,
      photoDamage)
    ).download('Poliza-' + claim.sticker + '-vehiculo.pdf');
  }

  generatePdfVehicleCreate(claim: Claim, photoSticker: string, photoLicensePlate: string, photoDamage: string) {
    return pdfMake.createPdf(this.getVehicleDefinition(
      claim,
      photoSticker,
      photoLicensePlate,
      photoDamage)
    );
  }

  getVehicleDefinition(claim: Claim, photoSticker: string, photoLicensePlate: string, photoDamage: string) {
    return {
      content: [
        {
          text: 'Poliza - ' + claim.sticker,
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          text: 'Vehículo',
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          image: photoSticker,
          margin: [0, 0, 0, 10],
          maxWidth: 500,
          alignment: 'center'
        },
        {
          image: photoLicensePlate,
          margin: [0, 0, 0, 10],
          maxWidth: 500,
          alignment: 'center'
        },
        {
          image: photoDamage,
          margin: [0, 0, 0, 10],
          maxWidth: 500,
          alignment: 'center'
        }
      ],
      styles: {
        name: {
          fontSize: 16,
          bold: true
        }
      }
    };
  }

  generatePdfFormatClaim(
    claim: Claim,
    bill: Bill,
    photoFrontDocumentCard: string,
    photoUpperDocumentCard: string,
    photoSticker: string,
    photoLicensePlate: string,
    photoDamage: string,
    imageLogoCole: string,
    photoBill: string
  ) {
    const myTableLayouts = {
      exampleLayout: {
        hLineWidth: (i, node) => {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return (i === node.table.headerRows) ? 2 : 1;
        },
        vLineWidth: (i) => {
          return 0;
        },
        hLineColor: (i) => {
          return i === 1 ? 'black' : '#aaa';
        },
        paddingLeft: (i) => {
          return i === 0 ? 0 : 8;
        },
        paddingRight: (i, node) => {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        }
      }
    };

    pdfMake.createPdf(this.getFormatClaimDefinition(
      claim,
      bill,
      photoFrontDocumentCard,
      photoUpperDocumentCard,
      photoSticker,
      photoLicensePlate,
      photoDamage,
      imageLogoCole,
      photoBill),
      myTableLayouts
    ).download('Poliza-' + claim.sticker + '-formato.pdf');
  }

  generatePdfFormatClaimCreate(
    claim: Claim,
    bill: Bill,
    photoFrontDocumentCard: string,
    photoUpperDocumentCard: string,
    photoSticker: string,
    photoLicensePlate: string,
    photoDamage: string,
    imageLogoCole: string,
    photoBill: string
  ) {
    const myTableLayouts = {
      exampleLayout: {
        hLineWidth: (i, node) => {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return (i === node.table.headerRows) ? 2 : 1;
        },
        vLineWidth: (i) => {
          return 0;
        },
        hLineColor: (i) => {
          return i === 1 ? 'black' : '#aaa';
        },
        paddingLeft: (i) => {
          return i === 0 ? 0 : 8;
        },
        paddingRight: (i, node) => {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        }
      }
    };

    return pdfMake.createPdf(this.getFormatClaimDefinition(
      claim,
      bill,
      photoFrontDocumentCard,
      photoUpperDocumentCard,
      photoSticker,
      photoLicensePlate,
      photoDamage,
      imageLogoCole,
      photoBill),
      myTableLayouts
    );
  }

  getFormatClaimDefinition(
    claim: Claim,
    bill: Bill,
    photoFrontDocumentCard: string,
    photoUpperDocumentCard: string,
    photoSticker: string,
    photoLicensePlate: string,
    photoDamage: string,
    imageLogoCole: string,
    photoBill: string
  ) {
    const imageHeight = 115;

    return {
      // a string or { width: number, height: number }
      pageSize: 'LETTER',

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [ 40, 10, 40, 10 ],
      content: [
        {
          image: imageLogoCole,
          alignment: 'center',
          width: 200,
          margin: [0, 0, 0, 10]
        },
        {
          layout: {
            fillColor: (i, node) => {
              return (i % 2 === 0) ? '#fafafe' : null;
            },
            hLineColor: (i, node) => {
              return 'black';
            },
            vLineColor: (i, node) => {
              return 'black';
            }
          },
          table: {
            width: 600,
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: [105, 65, 70, 100, 50, 90],
            body: [
              [
                {
                  text: 'FORMATO DE RECLAMACIÓN\nVIDRIO SEGURO',
                  colSpan: 4,
                  rowSpan: 2,
                  alignment: 'center',
                  bold: true,
                  color: 'white',
                  fillColor: '#007bbe'
                },
                {},
                {},
                {},
                {text: 'Número de sticker', colSpan: 2, alignment: 'center', bold: true},
                {}
              ],
              [
                {},
                {},
                {},
                {},
                {text: claim.sticker, colSpan: 2, alignment: 'center', bold: true},
                {}
              ],
              [
                {text: 'Fecha de la reclamación', colSpan: 3, alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {},
                {},
                {text: claim.claimRegistrationDate, colSpan: 3, alignment: 'center', bold: true, color: 'black', fillColor: 'white'},
                {},
                {}
              ],
              [
                {
                  text: 'Datos del Cliente',
                  colSpan: 6,
                  alignment: 'center',
                  bold: true,
                  color: 'white',
                  fillColor: '#007bbe'
                },
                {},
                {},
                {},
                {},
                {}
              ],
              [
                {text: 'Nombre del cliente', alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {text: claim.contactName, colSpan: 3, alignment: 'center', color: 'black', fillColor: 'white'},
                {},
                {},
                {text: claim.documentType, alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {text: claim.documentNumber, alignment: 'center', color: 'black', fillColor: 'white'},
              ],
              [
                {text: 'E-mail', alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {text: claim.email, colSpan: 3, alignment: 'center', color: 'black', fillColor: 'white'},
                {},
                {},
                {text: 'Teléfono', alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {text: claim.phone, alignment: 'center', color: 'black', fillColor: 'white'}
              ],
              [
                {
                  text: 'Datos del Punto de Venta',
                  colSpan: 6,
                  alignment: 'center',
                  bold: true,
                  color: 'white',
                  fillColor: '#007bbe'
                },
                {},
                {},
                {},
                {},
                {}
              ],
              [
                {text: 'Nombre del taller', alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {text: 'AUTOGLASS', colSpan: 2, alignment: 'center', color: 'black', fillColor: 'white'},
                {},
                {text: 'Punto de venta', colSpan: 2, alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {},
                {text: bill.costCenter, alignment: 'center', color: 'black', fillColor: 'white'},
              ],
              [
                {
                  text: 'Datos del Siniestro',
                  colSpan: 6,
                  alignment: 'center',
                  bold: true,
                  color: 'white',
                  fillColor: '#007bbe'
                },
                {},
                {},
                {},
                {},
                {}
              ],
              [
                {text: 'Placa del vehículo', alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {text: bill.licensePlate, colSpan: 2, alignment: 'center', color: 'black', fillColor: 'white'},
                {},
                {text: 'Fecha del siniestro', colSpan: 2, alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {},
                {text: claim.sinisterDate, alignment: 'center', color: 'black', fillColor: 'white'},
              ],
              [
                {text: 'Factura de venta', alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {text: bill.billNumber, colSpan: 2, alignment: 'center', color: 'black', fillColor: 'white'},
                {},
                {text: 'Valor de factura', colSpan: 2, alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {},
                {text: '$ ' + Util.currencyformat(bill.value.toString()), alignment: 'right', color: 'black', fillColor: 'white'},
              ],
              [
                {
                  text: 'Evidencias Fotográficas del Daño',
                  colSpan: 6,
                  alignment: 'center',
                  bold: true,
                  color: 'white',
                  fillColor: '#007bbe'
                },
                {},
                {},
                {},
                {},
                {}
              ],
              [
                {colSpan: 3, image: photoFrontDocumentCard, width: 230, alignment: 'center', height: imageHeight},
                {},
                {},
                {colSpan: 3, image: photoUpperDocumentCard, width: 230, alignment: 'center', height: imageHeight},
                {},
                {}
              ],
              [
                {colSpan: 3, image: photoDamage, width: 230, alignment: 'center', height: imageHeight},
                {},
                {},
                {colSpan: 3, image: photoLicensePlate, width: 230, alignment: 'center', height: imageHeight},
                {},
                {}
              ],
              [
                {colSpan: 3, image: photoSticker, width: 230, alignment: 'center', height: imageHeight},
                {},
                {},
                {colSpan: 3, image: photoBill, width: 230, alignment: 'center', height: imageHeight},
                {},
                {}
              ],
              [
                {text: 'Versión del siniestro', rowSpan: 3, alignment: 'left', bold: true, color: 'black', fillColor: 'white'},
                {text: claim.message, colSpan: 5, rowSpan: 3, alignment: 'justify', color: 'black', fillColor: 'white', fontSize: 8},
                {},
                {},
                {},
                {}
              ],
              [
                {},
                {},
                {},
                {},
                {},
                {}
              ],
              [
                {},
                {},
                {},
                {},
                {},
                {}
              ]
            ]
          }
        }
      ],
      styles: {
        name: {
          fontSize: 14,
          bold: true
        }
      }
    };
  }

  generatePdfFormatPeaceAndSaveDocument(claim: Claim, bill: Bill, imageLogoSeguro: string) {
    pdfMake.createPdf(this.getFormatPeaceAndSaveDefinition(
      claim,
      bill,
      imageLogoSeguro)
    ).download('Paz y Salvo-' + claim.sticker + '-formato.pdf');
  }

  getFormatPeaceAndSaveDefinition(claim: Claim, bill: Bill, imageLogoSeguro: string) {
    const fontNegritaSize = 14;
    const currentDate = new Date();
    let day = currentDate.getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }
    let month = (currentDate.getMonth() + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }
    const year = currentDate.getFullYear();

    return {
      // a string or { width: number, height: number }
      pageSize: 'LETTER',

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [ 70, 50, 70, 10 ],
      content: [
        {
          image: imageLogoSeguro,
          alignment: 'center',
          width: 300,
          height:100,
          margin: [0, 0, 0, 40]
        },
        {
          text: 'Ciudad ________________________________, ' + day + '/' + month + '/' + year ,
          margin: [0, 0, 0, 30]
        },
        {
          text: [
            'Registro No Sticker: ',
            {text: claim.sticker, bold: true}
          ],
          alignment: 'right',
          fontSize: fontNegritaSize,
          margin: [0, 0, 0, 30]
        },
        {
          text: 'DECLARACIÓN DE PAZ Y SALVO',
          bold: true,
          fontSize: 16,
          alignment: 'center',
          margin: [0, 0, 0, 30]
        },
        {
          text: [
            'En   calidad   de   asegurado(a), yo ',
            {text: claim.contactName, bold: true, fontSize: fontNegritaSize},
            ' identificado(a) con el número de documento ',
            {text: claim.documentNumber, bold: true, fontSize: fontNegritaSize},
            ', declaro a Paz y Salvo por todo concepto tanto a ',
            {text: 'COMERCIALIZADORA COLE S.A.S.', bold: true},
            ' y sus puntos de venta ',
            {text: 'AutoGlass.', bold: true},
            ' por los daños ocurridos en el parabrisas del vehículo   de placas ',
            {text: bill.licensePlate, bold: true, fontSize: fontNegritaSize},
            '. Por hechos acontecidos el día ',
            {text: claim.sinisterDate, bold: true, fontSize: fontNegritaSize},
            '.',
          ],
          alignment: 'justify',
          margin: [0, 0, 0, 60]
        },
        {
          text: [
            {text: 'Firma: ', bold: true},
            '___________________________________________________________'
          ],
          margin: [0, 0, 0, 10]
        },
        {
          text: [
            {text: 'Nombre: ', bold: true},
            {text: claim.contactName, fontSize: fontNegritaSize}
          ],
          margin: [0, 0, 0, 10]
        },
        {
          text: [
            {text: 'C.C.: ', bold: true},
            {text: claim.documentNumber, fontSize: fontNegritaSize}
          ],
          margin: [0, 0, 0, 10]
        },
        {
          text: [
            {text: 'Teléfono: ', bold: true},
            {text: claim.phone, fontSize: fontNegritaSize}
          ],
          margin: [0, 0, 0, 10]
        }
      ],
      styles: {
        name: {
          fontSize: 14,
          bold: true
        }
      }
    };
  }

  generatePdfPeaceAndSave(claim: Claim, photoPeaceAndSave: any) {
    pdfMake.createPdf(this.getFormatPeaceAndSaveDocumentDefinition(
      claim,
      photoPeaceAndSave
      )
    ).download('Paz y Salvo-' + claim.sticker + '.pdf');
  }

  generatePdfPeaceAndSaveCreate(claim: Claim, photoPeaceAndSave: any) {
    return pdfMake.createPdf(this.getFormatPeaceAndSaveDocumentDefinition(
      claim,
      photoPeaceAndSave
      )
    );
  }

  getFormatPeaceAndSaveDocumentDefinition(claim: Claim, photoPeaceAndSave: string) {
    return {
      // a string or { width: number, height: number }
      pageSize: 'LETTER',

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [ 20, 20, 20, 20 ],
      content: [
        {
          image: photoPeaceAndSave,
          alignment: 'center',
          maxWidth: 550,
          maxHeight: 750
        }
      ],
      styles: {
        name: {
          fontSize: 14,
          bold: true
        }
      }
    };
  }

  generatePdfBill(claim: Claim, photoBill: any) {
    pdfMake.createPdf(this.getFormatBillDefinition(
      claim,
      photoBill
      )
    ).download('Factura-' + claim.sticker + '.pdf');
  }

  generatePdfBillCreate(claim: Claim, photoBill: any) {
    return pdfMake.createPdf(this.getFormatBillDefinition(
      claim,
      photoBill
      )
    );
  }

  getFormatBillDefinition(claim: Claim, photoBill: string) {
    return {
      // a string or { width: number, height: number }
      pageSize: 'LETTER',

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [ 20, 20, 20, 20 ],
      content: [
        {
          image: photoBill,
          alignment: 'center',
          maxWidth: 550,
          maxHeight: 750
        }
      ],
      styles: {
        name: {
          fontSize: 14,
          bold: true
        }
      }
    };
  }
}
