import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Claim } from '../../shared/models/claim.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../shared/modal/modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Util from '../../shared/utils/util';
import { ClaimService } from '../../services/claim.service';
import { faExclamationCircle, faCheckCircle, faTimesCircle, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { PdfMakeService } from '../../services/pdf-make.service';
import { BillService } from '../../services/bill.service';
import { Bill } from 'src/app/shared/models/bill.model';
import  JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-claim-detail',
  templateUrl: './claim-detail.component.html',
  styleUrls: ['./claim-detail.component.scss']
})
export class ClaimDetailComponent implements OnInit {

  /* icons */
  faExclamationCircle = faExclamationCircle;
  faCheckCircle = faCheckCircle;
  faTrashAlt = faTrashAlt;
  faDownload = faDownload;

  title: string;
  @Input() claim: Claim;
  @Input() existResult: boolean;
  @Output() changeExistResult = new EventEmitter();
  state: string;
  reason: string;
  updateStateForm: FormGroup;
  modalOptions: NgbModalOptions;
  spinnerMessage: string;
  photoFrontDocumentCard: {};
  photoUpperDocumentCard: {};
  photoSticker: {};
  photoLicensePlate: {};
  photoDamage: {};
  photoPeaceAndSave: {};
  photoBill: {};
  urlLogoCole = environment.functionsUrlLogoCole;
  imageLogoCole: {};
  urlLogoSeguro = environment.functionsUrlSeguro;
  imageLogoSeguro: {};
  billPdf: Bill;
  filePeaceAndSave;
  imgURLPeaceAndSave;
  isAccepted = false;
  isCompletedValid = true;
  extensionPDF: string;
  consecutiveNumberPrefix: string;
  isConsecutiveNumberValid = true;
  consecutiveNumberLegalized: string;
  consecutiveNumberFinalized: string;
  showState = 'input-group row mb-4';

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private claimService: ClaimService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private pdfMakeService: PdfMakeService,
    private billService: BillService
  ) { }

  ngOnInit() {
    this.state = Util.currentStatus(this.claim);
    this.reason = Util.currentReason(this.claim);
    this.consecutiveNumberFinalized = Util.currentMajorStatus(this.claim, 'FINALIZADO');
    this.consecutiveNumberLegalized = Util.currentMajorStatus(this.claim, 'LEGALIZADO');

    this.resetForm();

    this.modalOptions = {
      backdrop: 'static',
      centered: true,
      backdropClass: 'customBackdrop',
      size: 'lg'
    };
    this.photoFrontDocumentCard = {};
    this.photoUpperDocumentCard = {};
    this.photoSticker = {};
    this.photoLicensePlate = {};
    this.photoDamage = {};
    this.imageLogoCole = {};
    this.imageLogoSeguro = {};
    this.photoPeaceAndSave = {};
    this.photoBill = {};
    Util.toDataURL(this.claim.photoFrontDocumentCard, this.photoFrontDocumentCard);
    Util.toDataURL(this.claim.photoUpperDocumentCard, this.photoUpperDocumentCard);
    Util.toDataURL(this.claim.photoSticker, this.photoSticker);
    Util.toDataURL(this.claim.photoLicensePlate, this.photoLicensePlate);
    Util.toDataURL(this.claim.photoDamage, this.photoDamage);
    Util.toDataURL(this.urlLogoCole, this.imageLogoCole);
    Util.toDataURL(this.urlLogoSeguro, this.imageLogoSeguro);
    if (Util.extensionFile(this.claim.photoBillPath).toLowerCase() !== 'pdf') {
      Util.toDataURL(this.claim.photoBill, this.photoBill);
    } else {
      Util.toDataURL(environment.functionsUrlFactura, this.photoBill);
    }

    if (this.claim.photoPeaceAndSaveDocument) {
      Util.toDataURL(this.claim.photoPeaceAndSaveDocument, this.photoPeaceAndSave);

      if (this.state === 'ACEPTADO') {
        this.updateStateForm.controls['status'].setValue('FINALIZADO');
        this.changeState();
      }
    }

    this.setReasonValidator();
    this.getConsecutiveNumber();

    if (this.authService.getRole() === 'admin') {
      if (this.state === 'FINALIZADO') {
        this.updateStateForm.controls['status'].setValue('LEGALIZADO');
        this.changeState();
      }
    } else if (this.state === 'ACEPTADO' || this.state === 'FINALIZADO') {
      this.showState = 'd-none';

      if (this.state === 'FINALIZADO') {
        this.updateStateForm.controls['status'].setValue('LEGALIZADO');
        this.changeState();
      }
    }
  }

  viewImage(title: string, image: string) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      centered: true,
      size: 'lg'
    };
    const modalRef = this.modalService.open(ModalComponent, modalOptions);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.image = image;
  }

  resetForm() {
    this.updateStateForm = this.formBuilder.group({
      status: [this.state, Validators.required],
      reason: ['', Validators.required],
      consecutiveNumber: ['', [Validators.required, Validators.pattern(/[0-9]*/)]]
    });
  }

  updateState() {
    const state = this.updateStateForm.get('status').value;
    if (state === this.state) {
      this.changeStatusModal();
    } else if (state === 'FINALIZADO' && !this.claim.photoPeaceAndSaveDocument) {
      this.validateChangeStateToCompleted();
    } else if (this.updateStateForm.valid || this.isAccepted) {
      this.spinnerMessage = 'Se esta actualizando el estado del reclamo...';
      this.spinnerService.show();
      const data = {
        id: this.claim.id,
        state: this.updateStateForm.get('status').value,
        reason: this.updateStateForm.get('reason').value,
        consecutiveNumber: this.consecutiveNumberPrefix + this.updateStateForm.get('consecutiveNumber').value
      };

      this.claimService.updateStateForClaim(data).subscribe((querySnapshot: any) => {
        this.spinnerService.hide();
        if (querySnapshot.claim) {
          this.claim = querySnapshot.claim;
          this.claimStateSuccessModal();
          this.resetForm();
          this.changedExistResult();
        } else {
          this.claimNotFoundModal();
        }
      }, (error) => {
        this.spinnerService.hide();
        console.log(error);
      });
    }
  }

  claimNotFoundModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Actualizar reclamo';
    modalRef.componentInstance.content = 'Fallo la actualización del estado: ' + this.updateStateForm.get('status').value;
    modalRef.componentInstance.icon = faExclamationCircle;
  }

  claimStateSuccessModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Actualizar reclamo';
    modalRef.componentInstance.content = 'Estado actualizado correctamente';
    modalRef.componentInstance.icon = faCheckCircle;
  }

  setPhotoFrontDocumentCard(dataUrl: any) {
    this.photoFrontDocumentCard = dataUrl;
  }

  exportPDFDocumentIdentification() {
    this.pdfMakeService.generatePdfDocumentIdentification(
      this.claim,
      this.photoFrontDocumentCard['name'],
      this.photoUpperDocumentCard['name']
    );
  }

  exportPDFVehicle() {
    this.pdfMakeService.generatePdfVehicle(
      this.claim,
      this.photoSticker['name'],
      this.photoLicensePlate['name'],
      this.photoDamage['name']
    );
  }

  exportPDFFormatClaim() {
    if (!this.billPdf) {
      this.spinnerMessage = 'Se está generando el pdf...';
      this.spinnerService.show();
      this.billService.findBillByStickerForPdf(this.claim.sticker).subscribe((querySnapshot: any) => {
        this.spinnerService.hide();
        if (querySnapshot.bill) {
          this.billPdf = querySnapshot.bill;
          this.pdfMakeService.generatePdfFormatClaim(
            this.claim,
            this.billPdf,
            this.photoFrontDocumentCard['name'],
            this.photoUpperDocumentCard['name'],
            this.photoSticker['name'],
            this.photoLicensePlate['name'],
            this.photoDamage['name'],
            this.imageLogoCole['name'],
            this.photoBill['name']
          );
        } else {
        }
      }, (error) => {
        this.spinnerService.hide();
        console.log(error);
        // this.claimErrorModal();
      });
    } else {
      this.pdfMakeService.generatePdfFormatClaim(
        this.claim,
        this.billPdf,
        this.photoFrontDocumentCard['name'],
        this.photoUpperDocumentCard['name'],
        this.photoSticker['name'],
        this.photoLicensePlate['name'],
        this.photoDamage['name'],
        this.imageLogoCole['name'],
        this.photoBill['name']
      );
    }
  }

  exportPDFFormatPeaceAndSaveDocument() {
    if (!this.billPdf) {
      this.spinnerMessage = 'Se está generando el pdf...';
      this.spinnerService.show();
      this.billService.findBillByStickerForPdf(this.claim.sticker).subscribe((querySnapshot: any) => {
        this.spinnerService.hide();
        if (querySnapshot.bill) {
          this.billPdf = querySnapshot.bill;
          this.pdfMakeService.generatePdfFormatPeaceAndSaveDocument(
            this.claim,
            this.billPdf,
            this.imageLogoSeguro['name']
          );
        } else {
        }
      }, (error) => {
        this.spinnerService.hide();
        console.log(error);
        // this.claimErrorModal();
      });
    } else {
      this.pdfMakeService.generatePdfFormatPeaceAndSaveDocument(
        this.claim,
        this.billPdf,
        this.imageLogoSeguro['name']
      );
    }
  }

  fileProgress(fileInput: any) {
    this.filePeaceAndSave = fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview
    const mimeType = this.filePeaceAndSave.type;
    this.extensionPDF = Util.extensionFile(this.filePeaceAndSave.name);
    if (mimeType.match(/image\/*/) || mimeType.match('application/pdf')) {
      const reader = new FileReader();

      reader.readAsDataURL(this.filePeaceAndSave);
      reader.onload = () => {
        if (this.extensionPDF.toLowerCase() === 'pdf') {
          this.imgURLPeaceAndSave = '../../../assets/images/pdf_factura.png';
        } else {
          this.imgURLPeaceAndSave = reader.result;
        }

        const imageObject = {
          image: reader.result,
          contentType: mimeType,
          type: this.extensionPDF
        };

        this.attachPeaceAndSaveDocument(imageObject);



      };
    }
  }

  attachPeaceAndSaveDocument(imageObject: any) {
    this.spinnerMessage = 'Adjuntando el paz y salvo...';
    this.spinnerService.show();
    this.claimService.uploadFilePeaceAndSaveForClaim(this.claim, imageObject).subscribe((querySnapshot: any) => {
      this.spinnerService.hide();
      if (querySnapshot.response) {
        this.claim.photoPeaceAndSaveDocumentPath = querySnapshot.filePath;
        this.claim.photoPeaceAndSaveDocument = querySnapshot.url;

        this.attachPeaceAndSaveDocumentSuccessModal();
        this.photoPeaceAndSave = {};
        Util.toDataURL(this.claim.photoPeaceAndSaveDocument, this.photoPeaceAndSave);

        //Se adjuntó correctamente el paz y salvo, se cambia el valor del combo a FINALIZADO
        this.updateStateForm.controls['status'].setValue('FINALIZADO');
        this.changeState();

      } else {
      }
    }, (error) => {
      this.spinnerService.hide();
      console.log(error);
      // this.claimErrorModal();
    });
  }

  attachPeaceAndSaveDocumentSuccessModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Paz y salvo';
    modalRef.componentInstance.content = 'Paz y salvo adjuntado correctamente.';
    modalRef.componentInstance.icon = faCheckCircle;
  }

  deletePeaceAndSaveDocumentSuccessModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Paz y salvo';
    modalRef.componentInstance.content = 'Paz y salvo eliminado correctamente.';
    modalRef.componentInstance.icon = faCheckCircle;
  }

  attachPeaceAndSaveDocumentDeleteModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Paz y salvo';
    modalRef.componentInstance.content = '¿Esta seguro de eliminar el paz y salvo?';
    modalRef.componentInstance.icon = faExclamationCircle;
    modalRef.componentInstance.confirm = 'Si';
    modalRef.componentInstance.cancel = 'No';

    modalRef.result.then((response) => {
      if (response === true) {
        this.deletePeaceAndSaveDocument();
      }
    });
  }

  changeStatusModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Actualizar reclamo';
    modalRef.componentInstance.content = 'El reclamo ya se encuentra en estado ' + this.updateStateForm.get('status').value + '. ' +
      'Cambielo al nuevo estado deseado.';
    modalRef.componentInstance.icon = faExclamationCircle;
  }

  deletePeaceAndSaveDocument() {
    this.spinnerMessage = 'Borrando el paz y salvo...';
    this.spinnerService.show();
    this.claimService.deleteFilePeaceAndSaveForClaim(this.claim).subscribe((querySnapshot: any) => {
      this.spinnerService.hide();
      if (querySnapshot.response) {
        this.claim.photoPeaceAndSaveDocument = null;
        this.claim.photoPeaceAndSaveDocumentPath = null;
        this.imgURLPeaceAndSave = null;
        this.deletePeaceAndSaveDocumentSuccessModal();
        this.photoPeaceAndSave = {};
        this.updateStateForm.controls['status'].setValue(this.state);
        this.changeState();
      } else {
      }
    }, (error) => {
      this.spinnerService.hide();
      console.log(error);
      // this.claimErrorModal();
    });
  }

  getClassState() {
    let classStyle = 'control-label state-style ';
    switch (this.state) {
      case 'ACEPTADO':
        classStyle += 'state-green';
        break;
      case 'FINALIZADO':
        classStyle += 'state-green';
        break;
      case 'LEGALIZADO':
        classStyle += 'state-green';
        break;
      case 'OBJETADO':
        classStyle += 'state-yellow';
        break;
      case 'RECHAZADO':
        classStyle += 'state-red';
        break;
      case 'PENDIENTE':
        classStyle += 'state-black';
        break;
    }
    return classStyle;
  }

  showReason() {
    return (this.state !== 'PENDIENTE');
  }

  changeState() {
    this.setReasonValidator();
    this.getConsecutiveNumber();
  }

  setReasonValidator() {
    const state = this.updateStateForm.get('status').value;
    this.isAccepted = false;
    this.isCompletedValid = true;
    if (state === 'RECHAZADO' || state === 'OBJETADO') {
      this.updateStateForm.get('reason').setValidators([Validators.required, Validators.minLength(60)]);
    } else if (state === 'ACEPTADO') {
      this.updateStateForm.get('reason').setValidators([]);
      this.isAccepted = true;
    } else if (state === 'FINALIZADO' || state === 'LEGALIZADO') {
      this.updateStateForm.get('reason').setValidators([]);
      this.isCompletedValid = true;
    } else {
      this.updateStateForm.get('reason').setValidators([Validators.required]);
      if (this.updateStateForm.get('reason').value.length === 0) {
        this.isCompletedValid = false;
      } else {
        this.isCompletedValid = true;
      }
    }
  }

  exportPDFPeaceAndSave() {
    this.pdfMakeService.generatePdfPeaceAndSave(
      this.claim,
      this.photoPeaceAndSave['name']
    );
  }

  showImgPhotoBill() {
    const extension = Util.extensionFile(this.claim.photoBillPath);
    let urlFile = this.claim.photoBill;
    if (extension && extension.toLowerCase() === 'pdf') {
      urlFile = '../../../assets/images/pdf_factura.png';
    }

    return urlFile;
  }

  showImgPhotoPeaceAndSave() {
    const extension = Util.extensionFile(this.claim.photoPeaceAndSaveDocumentPath);
    let urlFile = this.claim.photoPeaceAndSaveDocument;
    if (extension && extension.toLowerCase() === 'pdf') {
      urlFile = '../../../assets/images/pdf_factura.png';
    }

    return urlFile;
  }

  showPhotoBill() {
    const extension = Util.extensionFile(this.claim.photoBillPath);
    if (extension && extension.toLowerCase() === 'pdf') {
      window.open(this.claim.photoBill, '_blank');
    } else {
      this.viewImage('Daño', this.claim.photoBill);
    }
  }

  showPeaceAndSave() {
    const extension = Util.extensionFile(this.claim.photoPeaceAndSaveDocumentPath);
    if (extension && extension.toLowerCase() === 'pdf') {
      window.open(this.claim.photoPeaceAndSaveDocument, '_blank');
    } else {
      this.viewImage('Paz y Salvo', this.claim.photoPeaceAndSaveDocument);
    }
  }

  exportPDFBill() {
    const extension = Util.extensionFile(this.claim.photoBillPath);
    if (extension && extension.toLowerCase() === 'pdf') {
      window.open(this.claim.photoBill, '_blank');
    } else {
      this.pdfMakeService.generatePdfBill(
        this.claim,
        this.photoBill['name']
      );
    }
  }

  validateChangeStateToCompleted() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Paz y salvo';
    modalRef.componentInstance.content = 'Falta adjuntar el paz y salvo para poder completar  el reclamo.';
    modalRef.componentInstance.icon = faExclamationCircle;
  }

  validateGenerateZip() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Generando paquete comprimido';
    modalRef.componentInstance.content = 'Falta adjuntar el paz y salvo para poder descargar el paquete comprimido.';
    modalRef.componentInstance.icon = faExclamationCircle;
  }

  generateZip() {
    if (!this.claim.photoPeaceAndSaveDocument) {
      this.validateGenerateZip();
    } else if (!this.billPdf) {
      this.spinnerMessage = 'Se está generando el zip...';
      this.spinnerService.show();
      this.billService.findBillByStickerForPdf(this.claim.sticker).subscribe((querySnapshot: any) => {
        this.spinnerService.hide();
        if (querySnapshot.bill) {
          this.billPdf = querySnapshot.bill;

          this.pdfMakeService.generatePdfDocumentIdentificationCreate(
            this.claim,
            this.photoFrontDocumentCard['name'],
            this.photoUpperDocumentCard['name']
          ).getBase64((base64Identification) => {
            const zip = new JSZip();
            // zip.file("Hello.txt", "Hello World\n");
            zip.file('Poliza-' + this.claim.sticker + '-documento_identificacion.pdf', base64Identification, { base64: true });

            this.pdfMakeService.generatePdfFormatClaimCreate(
              this.claim,
              this.billPdf,
              this.photoFrontDocumentCard['name'],
              this.photoUpperDocumentCard['name'],
              this.photoSticker['name'],
              this.photoLicensePlate['name'],
              this.photoDamage['name'],
              this.imageLogoCole['name'],
              this.photoBill['name']
            ).getBase64((base64Format) => {
              zip.file('Poliza-' + this.claim.sticker + '-formato.pdf', base64Format, { base64: true });

              this.pdfMakeService.generatePdfVehicleCreate(
                this.claim,
                this.photoSticker['name'],
                this.photoLicensePlate['name'],
                this.photoDamage['name']
              ).getBase64((base64Vehicle) => {
                zip.file('Poliza-' + this.claim.sticker + '-vehiculo.pdf', base64Vehicle, { base64: true });

                this.pdfMakeService.generatePdfPeaceAndSaveCreate(
                  this.claim,
                  this.photoPeaceAndSave['name']
                ).getBase64((base64PeaceAndSave) => {
                  zip.file('Paz y Salvo-' + this.claim.sticker + '.pdf', base64PeaceAndSave, { base64: true });

                  if (Util.extensionFile(this.claim.photoBillPath).toLowerCase() === 'pdf') {
                    zip.file('Factura-' + this.claim.sticker + '.pdf', this.urlToPromise(this.claim.photoBill), { binary: true });

                    zip.generateAsync({ type: 'blob' })
                      .then((content) => {
                        // see FileSaver.js
                        saveAs(content, 'Poliza-' + this.claim.sticker + '.zip');
                      });
                  } else {
                    this.pdfMakeService.generatePdfBillCreate(
                      this.claim,
                      this.photoBill['name']
                    ).getBase64((base64Bill) => {
                      zip.file('Factura-' + this.claim.sticker + '.pdf', base64Bill, { base64: true });

                      zip.generateAsync({ type: 'blob' })
                        .then((content) => {
                          // see FileSaver.js
                          saveAs(content, 'Poliza-' + this.claim.sticker + '.zip');
                        });
                    });
                  }
                });
              });
            });

          });
        } else {
        }
      }, (error) => {
        this.spinnerService.hide();
        console.log(error);
        // this.claimErrorModal();
      });
    }
  }

  urlToPromise(url) {
    return new Promise<any>((resolve, reject) => {
      JSZipUtils.getBinaryContent(url, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  changedExistResult() {
    this.changeExistResult.emit(this.claim);
  }

  validateReason(value: string) {
    if (this.updateStateForm.get('status').value === 'LEGALIZADO' || this.updateStateForm.get('status').value === 'FINALIZADO') {
      if (value.length === 0) {
        this.isCompletedValid = false;
      } else {
        this.isCompletedValid = true;
      }
    }
  }

  getConsecutiveNumber() {
    const state = this.updateStateForm.get('status').value;
    this.isConsecutiveNumberValid = true;
    if (this.state != state && state === 'FINALIZADO') {
      this.consecutiveNumberPrefix = 'O' + this.getPointSaleConsecutive() + '-';
      this.updateStateForm.get('consecutiveNumber').setValidators([Validators.required]);
    } else if (this.state == 'FINALIZADO') {
      // this.consecutiveNumberPrefix = 'XIJ' + this.getPointSaleConsecutive() + '-';
      this.consecutiveNumberPrefix = 'XIJO';
      this.updateStateForm.get('consecutiveNumber').setValidators([Validators.required]);
    } else {
      this.consecutiveNumberPrefix = '';
      this.updateStateForm.get('consecutiveNumber').setValidators([]);
    }

    this.validateConsecutiveNumber();
  }

  validateConsecutiveNumber() {
    const status = this.updateStateForm.get('status').value;
    const consecutiveNumber = this.updateStateForm.get('consecutiveNumber').value;
    if (status === 'LEGALIZADO' || status === 'FINALIZADO') {
      if (consecutiveNumber.length === 0) {
        this.isConsecutiveNumberValid = false;
      } else {
        this.isConsecutiveNumberValid = true;
      }
    } else {
      this.isConsecutiveNumberValid = true;
    }
  }

  getPointSaleConsecutive() {
    let pointSale = this.claim.pointSale.replace('V', '');
    pointSale = Util.zfill(pointSale, 2);

    return pointSale;
  }

  isNumberKey(evt: any) {
    const charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
}
