import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {faEnvelopeOpen} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  /* icons */
  faPhone = faPhone;
  faEnvelopeOpen = faEnvelopeOpen;


  constructor(private authService: AuthService, private location: Location, private router: Router) { }
  public isLogged = false;

  ngOnInit() {
    this.onCheckUser();
  }

  onCheckUser(): void {
  }

  logout() {
    this.authService.logout().then(() => {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('sesionInitTime');
      sessionStorage.removeItem('role');
      this.router.navigate(['/claim']);
    });
  }
}
