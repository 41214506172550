import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {faEnvelopeOpen} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  faPhone = faPhone;
  faEnvelopeOpen = faEnvelopeOpen;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.delayRefreshToken();
  }
}
