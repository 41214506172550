import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, DocumentReference} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Claim, IClaim} from '../shared/models/claim.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DataDbService {
private claimColletion: AngularFirestoreCollection<Claim>;

  constructor(private afs: AngularFirestore, private storage: AngularFireStorage ) {
    this.claimColletion = afs.collection <Claim> ('claims');
   }

   saveMessage(newClaim: any): Promise<DocumentReference> {
     return this.claimColletion.add(newClaim);
   }


}
