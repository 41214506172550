import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {searchClaimRoute} from './search-claim.route';
import {SearchClaimComponent} from './search-claim.component';
import {SearchClaimStickerComponent} from './filters/search-claim-sticker/search-claim-sticker.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { SearchClaimStateComponent } from './filters/search-claim-state/search-claim-state.component';
import {ClaimDetailComponent} from '../claim-detail/claim-detail.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerModule} from 'ngx-spinner';



@NgModule({
  declarations: [
    SearchClaimComponent,
    SearchClaimStickerComponent,
    SearchClaimStateComponent,
    ClaimDetailComponent,
  ],
  exports: [RouterModule, ClaimDetailComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(searchClaimRoute),
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    NgbModule
  ],
  entryComponents: [
    SearchClaimStickerComponent,
    SearchClaimStateComponent,
    ClaimDetailComponent
  ]
})
export class SearchClaimModule { }
