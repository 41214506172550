import { Injectable } from '@angular/core';
import * as json2csv from 'json2csv'; // convert json file to csv
import { saveAs } from 'file-saver';  // save the file

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  json2csvParser = json2csv.Parser;

  constructor() { }

  public downloadFile(data:any, fields? : any, filename = 'data'){
    let csvData = this.convertToCSV(data, fields);
    let file = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(file, filename + '.csv');
  }



  public convertToCSV(objArray: any, fields?: any) {
      let json2csvParser = new this.json2csvParser({ fields: fields });
      let csv = json2csvParser.parse(objArray);
      return csv;
  }
}
