import {Routes} from '@angular/router';
import {SearchClaimComponent} from './search-claim.component';
import {SearchClaimStickerComponent} from './filters/search-claim-sticker/search-claim-sticker.component';
import {SearchClaimStateComponent} from './filters/search-claim-state/search-claim-state.component';
import {CanActivateAuthGuard} from '../../guards/can-activate-auth.guard';

export const searchClaimRoute: Routes = [
  {
    path: '',
    component: SearchClaimComponent,
    children: [
      {
        path: 'search-claim-sticker',
        component: SearchClaimStickerComponent,
        canActivate: [CanActivateAuthGuard],
        data: { privileges: ['admin', 'approver', 'treasury', 'vendor'] }
      },
      {
        path: 'search-claim-state',
        component: SearchClaimStateComponent,
        canActivate: [CanActivateAuthGuard],
        data: { privileges: ['admin', 'approver', 'treasury', 'vendor'] }
      },
      {
        path: '',
        redirectTo: '/search-claim-sticker',
        pathMatch: 'full'
      }
    ]
  }
];
