import {Injectable, OnInit} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import {delay, map} from 'rxjs/operators';
import { auth } from 'firebase/app';
import { isNullOrUndefined } from 'util';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { UserInterface } from '../shared/models/user';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';

const firebase = require('firebase');
const firebaseui = require('firebaseui');

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userApiUrl = environment.functionsUrl.concat('/userApi/user');
  isLogged = false;
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private afsAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private http: HttpClient,
    private router: Router,
  ) { }

  registerUser(email: string, pass: string) {
    return new Promise((resolve, reject) => {
      this.afsAuth.auth.createUserWithEmailAndPassword(email, pass)
        .then(userData => {
          resolve(userData),
            this.updateUserData(userData.user)
        }).catch(err => console.log(reject(err)))
    });
  }

  loginEmailUser(email: string, pass: string) {
    return firebase.auth().signInWithEmailAndPassword(email, pass);
  }

  logout() {
    return firebase.auth().signOut();
  }

  loginFacebookUser() {
    return this.afsAuth.auth.signInWithPopup(new auth.FacebookAuthProvider())
      .then(credential => this.updateUserData(credential.user))
  }

  loginGoogleUser() {
    return this.afsAuth.auth.signInWithPopup(new auth.GoogleAuthProvider())
      .then(credential => this.updateUserData(credential.user))
  }

  logoutUser() {
    return this.afsAuth.auth.signOut();
  }

  isAuth() {
    return this.afsAuth.authState.pipe(map(auth => auth));
  }

  private updateUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data: UserInterface = {
      id: user.uid,
      email: user.email,
      roles: {
        editor: true
      }
    }
    return userRef.set(data, { merge: true })
  }


  isUserAdmin(userUid) {
    return this.afs.doc<UserInterface>(`users/${userUid}`).valueChanges();
  }

  getCurrentUser(): UserInterface {
    let user_string = localStorage.getItem("currentUser");
    if (!isNullOrUndefined(user_string)) {
      let user: UserInterface = JSON.parse(user_string);
      return user;
    } else {
      return null;
    }
  }

  loginTest() {
    this.isLogged = true;
  }

  isAuthenticated() {
    return this.getToken() != null;
  }

  setToken() {
    return firebase.auth().currentUser.getIdToken(false).then(async (token: string) => {
      sessionStorage.setItem('token', token);
      this.delayRefreshToken();
      return this.obtainRole();
    });
  }

  getToken() {
    return sessionStorage.getItem('token');
  }

  getRole() {
    return sessionStorage.getItem('role');
  }

  refreshToken() {
    return firebase.auth().currentUser.getIdToken(true);
  }

  setInitSesionTime(initTime: string) {
    const initDateTime = new Date(initTime);
    sessionStorage.setItem('sesionInitTime', initDateTime.toString());
  }

  delayRefreshToken() {
    if (this.getToken()) {
      const initDateTime = new Date(sessionStorage.getItem('sesionInitTime'));
      const currentDateTime = new Date();
      const currentSesionTime = currentDateTime.getTime() - initDateTime.getTime();
      const sesionTime = 1000 * 60 * 60; // 1 hour in milliseconds
      let missingTime = sesionTime - currentSesionTime - (60 * 5 * 1000);
      if (missingTime < 0) {
        missingTime = 1000;
      }

      setTimeout(() => {
        this.refreshToken().then((newToken: string) => {
          sessionStorage.setItem('token', newToken);
          sessionStorage.setItem('', new Date().toString())
          this.delayRefreshToken();
        });
      }, missingTime);
    }
  }

  async obtainRole() {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    const url = this.userApiUrl + '/get-user-extend';
    return this.http.post(url, JSON.stringify({data: ''}), httpOptions).subscribe((querySnapshot: any) => {
      if (querySnapshot.userExtend) {
        sessionStorage.setItem('role', querySnapshot.userExtend.role);
        return;
      }
    }, (error) => {
      console.log(error);
    });
  }

  hasPrivileges(listRole: Array<string>) {
    return listRole.includes(sessionStorage.getItem('role'));
  }
}
