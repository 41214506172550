import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListPriceService {
  listPrice = environment.functionsUrl.concat('/glassDescriptionApi');


  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient
  ) { }

  getList(formListPrice: any) {
    // Http Headers
    console.log('aca imprimiendo ' + formListPrice.referencia)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.listPrice + '/sendFormListPrice';
    return this.http.post(url, JSON.stringify(formListPrice), httpOptions);
  }
  //guardo la url de la carga de la imagen
  ImageList(imageListPrice: any) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.listPrice + '/sendFormImageListPrice';
    return this.http.post(url, JSON.stringify(imageListPrice), httpOptions);
  }
  getSearchReference(reference: any) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let searchReference: any = {
      reference: reference
    };
    const url = this.listPrice + '/searchReference';
    return this.http.post(url, JSON.stringify(searchReference), httpOptions);
  }
}