import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import { AuthService } from '../services/auth.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CanActivateAuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const privileges = route.data.privileges;
    if (this.authService.isAuthenticated()) {
      // login TRUE
      return true;
    } else {
      this.authService.logout();
      this.router.navigate(['/claim']);
      return false;
    }
  }
}
