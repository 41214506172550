import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ModalComponent} from '../../shared/modal/modal.component';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  /* icons */
  faExclamationCircle = faExclamationCircle;

  public email = '';
  public password = '';
  spinnerMessage: string;
  modalOptions: NgbModalOptions;
  loginForm: FormGroup;
  emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.createLoginForm();
    this.modalOptions = {
      backdrop: 'static',
      centered: true,
      backdropClass: 'customBackdrop',
      size: 'lg'
    };
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(5), Validators.pattern(this.emailPattern)]],
      password: ['', [Validators.required]]
    });
  }

  onLogin(): void {
    if (this.loginForm.valid) {
      this.spinnerMessage = 'Ingresando...';
      this.spinnerService.show();
      const email = this.loginForm.get('username').value;
      const password = this.loginForm.get('password').value
      this.authService.loginEmailUser(email, password)
        .then(async (res) => {
          this.authService.setInitSesionTime(res.user.metadata.lastSignInTime);
          await this.authService.setToken().then(async () => {
                this.spinnerService.hide();
                this.onLoginRedirect();
            }, (error) => {
              console.log(error);
            });
        }).catch(err => {
        this.spinnerService.hide();
        console.log('err', err.message);
        this.loginCredentialsError();
      });
    }
  }

  onLoginGoogle(): void {

    this.authService.loginGoogleUser()
      .then((res) => {
        this.spinnerService.hide();
        this.onLoginRedirect();
      }).catch(err => {
        this.spinnerService.hide();
        console.log('err', err.message);

    });
  }

  // onLoginFacebook(): void {
  //   this.authService.loginFacebookUser()
  //     .then((res) => {
  //       this.onLoginRedirect();
  //     }).catch(err => console.log('err', err.message));
  // }

  onLogout() {
    this.authService.logoutUser();
  }

  onLoginRedirect(): void {
    this.router.navigate(['search-claim']);
  }

  loginCredentialsError() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Error de autenticación';
    modalRef.componentInstance.content = 'Usuario o contraseña incorrectos, vuelva a intentarlo.';
    modalRef.componentInstance.icon = faExclamationCircle;
  }

  cancelLogin() {
    this.router.navigate(['/']);
  }
}
