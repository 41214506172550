import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Claim} from '../../../../shared/models/claim.model';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../../services/auth.service';
import {ClaimService} from '../../../../services/claim.service';
import {faExclamationCircle, faTimesCircle, faSearch} from '@fortawesome/free-solid-svg-icons';
import {ModalComponent} from '../../../../shared/modal/modal.component';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-search-claim-sticker',
  templateUrl: './search-claim-sticker.component.html',
  styleUrls: ['./search-claim-sticker.component.scss']
})
export class SearchClaimStickerComponent implements OnInit {

  /* icons */
  faSearch = faSearch;

  searchForm: FormGroup;

  claim: Claim;
  imageDefault: string;

  modalOptions: NgbModalOptions;
  existResult: boolean;
  spinnerMessage: string;

  constructor(
    private authService: AuthService,
    private claimService: ClaimService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    this.existResult = false;
    this.imageDefault = '../../../assets/images/default-image.png';

    this.authService.loginTest();

    this.resetForm();

    this.claim = new Claim();

    this.modalOptions = {
      backdrop: 'static',
      centered: true,
      backdropClass: 'customBackdrop'
    };
  }

  findClaim() {
    this.spinnerMessage = 'Consultando sticker...';
    this.spinnerService.show();
    this.existResult = false;
    const sticker = this.searchForm.controls['search'].value;
    this.claimService.findClaimBySticker(sticker).subscribe((querySnapshot: any) => {
      this.spinnerService.hide();
      if (querySnapshot.size > 0) {
        this.existResult = true;
        querySnapshot.forEach((doc) => {
          this.claim = doc.data();
          this.claim.id = doc.id;
        });
      } else {
        this.claimNotFoundModal();
      }
    }, (error) => {
      console.log(error);
      this.spinnerService.hide();
      this.claimErrorModal();
    });
  }

  resetForm() {
    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required]
    });
  }

  claimNotFoundModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Consultar reclamo';
    modalRef.componentInstance.content = 'El número de sticker no fue encontrado';
    modalRef.componentInstance.icon = faExclamationCircle;
  }

  claimErrorModal() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Consultar reclamo';
    modalRef.componentInstance.content = 'Se produjo un error al realizar la consult. Vuelva a intentarlo.';
    modalRef.componentInstance.icon = faTimesCircle;
  }

  changedExistResult() {
    this.existResult = false;
    this.claim = new Claim();
    this.resetForm();
  }
}
