import { Component, OnInit } from '@angular/core';
import {FileService} from '../../services/file.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ModalComponent} from '../../shared/modal/modal.component';
import {faCheckCircle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-safe-file',
  templateUrl: './upload-safe-file.component.html',
  styleUrls: ['./upload-safe-file.component.scss']
})
export class UploadSafeFileComponent implements OnInit {

  /* icons */
  faExclamationCircle = faExclamationCircle;
  faCheckCircle = faCheckCircle;

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  spinnerMessage: string;
  modalOptions: NgbModalOptions;

  constructor(
    private fileService: FileService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.spinnerMessage = 'Cargando archivo...';

    this.modalOptions = {
      backdrop: 'static',
      centered: true,
      backdropClass: 'customBackdrop',
      size: 'lg'
    };
  }

  fileProgress(fileInput: any) {
    this.fileData = fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet*/) != null) {
      this.previewUrl = '../../../assets/images/svg/excel.svg';
    }
  }

  onSubmit() {
    if (this.fileData) {
      this.spinnerService.show();
      this.fileService.uploadFileExcelBills(this.fileData).subscribe((response) => {
        this.spinnerService.hide();
        console.log(response);
        this.uploadFileSuccess();
      }, (error) => {
        console.log(error);
        this.spinnerService.hide();
        this.uploadFileError();
      });
    }
  }

  uploadFileSuccess() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Cargar archivo';
    modalRef.componentInstance.content = 'Se cargó el archivo correctamente';
    modalRef.componentInstance.icon = faCheckCircle;
  }

  uploadFileError() {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Cargar archivo';
    modalRef.componentInstance.content = 'Error al cargar el archivo, vuelva a intentarlo';
    modalRef.componentInstance.icon = faExclamationCircle;
  }
}
