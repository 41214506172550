import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClaimComponent } from './components/claim/claim.component';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {environment } from '../environments/environment';
import {DataDbService} from './services/data-db.service';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { LoginComponent } from './components/login/login.component';
import {AngularFireAuth} from '@angular/fire/auth';
import { UploadSafeFileComponent } from './components/upload-safe-file/upload-safe-file.component';
import { NavbarComponent } from './shared/layouts/navbar/navbar.component';
import { DataApiService } from 'src/app/services/data-api.service';
import { SidebarComponent } from './shared/layouts/sidebar/sidebar.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './shared/modal/modal.component';
import {SearchClaimModule} from './components/search-claim/search-claim.module';
import { HeaderComponent } from './shared/heater/header.component';
import {InterceptorService} from './services/interceptor.service';
import {CanActivateAuthGuard} from './guards/can-activate-auth.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FooterComponent } from './shared/layouts/footer/footer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CalendarModule} from 'primeng/calendar';
import { PriceListComponent } from './components/price-list/price-list.component';
// import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';



@NgModule({
  declarations: [
    AppComponent,
    ClaimComponent,
    LoginComponent,
    UploadSafeFileComponent,
    NavbarComponent,
    SidebarComponent,
    ModalComponent,
    HeaderComponent,
    FooterComponent,
    PriceListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp (environment.firebaseConfig),
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireStorageModule,
    FontAwesomeModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    SearchClaimModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    CalendarModule,
    NgbModule,
    // ComboBoxModule


    // AngularFireDatabaseModule
  ],
  entryComponents: [ModalComponent],
  providers: [DataDbService, AppComponent, AngularFireAuth, DataApiService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }, CanActivateAuthGuard],
  bootstrap: [AppComponent]
})

export class AppModule {}
