import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent, HttpUserEvent
} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): any {

    /* Get accessToken from authenticated user */
    const token = this.authService.getToken();

    /* Modify current request */
    /*const reqClone = req.clone({
      url: `${this.getURLBasePath(req.url)}/${ req.url }`
    });*/
    const reqClone = req.clone({
      setHeaders: {
        Authorization: 'Bearer '.concat(token)
      }
    });

    // Continue with the execution
    return next.handle(reqClone).pipe(
      catchError((err) => {
        if (err.status === 401) {
          if (err.error.error === 'El token ha expirado, vuelva ha autenticarse') {
            return this.authService.refreshToken().then((newToken: string) => {
              sessionStorage.setItem('token', newToken);

              // Continue with the execution
              return next.handle(reqClone);
            });
          } else {
            // logout
            // return throwError(err);
          }
        }
      })
    );
  }

  getURLBasePath(url: string) {
    const path = url.split('/')[1];
    let urlBasePath;
    if (path === 'claimApi') {
      urlBasePath = 'https://us-central1-vidriera-f3d02.cloudfunctions.net';
    } else if (path === 'fileApi') {
      urlBasePath = 'https://us-central1-vidriera-f3d02.cloudfunctions.net';
    } else if (path === 'userApi') {
      urlBasePath = 'https://us-central1-vidriera-f3d02.cloudfunctions.net';
    } else {
      urlBasePath = window.location.origin;
    }
    return urlBasePath;
  }

}
