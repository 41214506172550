import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import {ClaimComponent} from './components/claim/claim.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import {NavbarComponent} from 'src/app/shared/layouts/navbar/navbar.component';
import {HeaderComponent} from 'src/app/shared/heater/header.component';
import {UploadSafeFileComponent } from 'src/app/components/upload-safe-file/upload-safe-file.component';
import {SearchClaimModule} from './components/search-claim/search-claim.module';
import {CanActivateAuthGuard} from './guards/can-activate-auth.guard';
import { PriceListComponent } from './components/price-list/price-list.component';

const routes: Routes = [
  { path: 'claim', component: ClaimComponent },
  { path: 'navbar',
    component: NavbarComponent,
    canActivate: [CanActivateAuthGuard],
    data: { privileges: ['admin', 'approver', 'treasury', 'vendor'] }
  },
  { path: 'header', component: HeaderComponent },
  { path: 'upload-safe-file',
    component: UploadSafeFileComponent,
    canActivate: [CanActivateAuthGuard],
    data: { privileges: ['admin', 'approver', 'treasury'] }
  },
  { path: 'login', component: LoginComponent },


  {
    path: 'search-claim',
    loadChildren: () => SearchClaimModule
  },
  {
    path: 'price-list',
    component: PriceListComponent
  },
  { path: '', redirectTo: '/claim', pathMatch: 'full' },
];

// @ts-ignore
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
